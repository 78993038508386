import React from "react";

export const VoteYes = ({ unit = "em", ...rest } = {}) => (
	<svg viewBox="0 0 26 26" {...rest}>
		<g
			id="Component/Game-Play/Selection/Yes"
			stroke="none"
			strokeWidth="1"
			fill="none"
			fillRule="evenodd"
		>
			<path
				d="M12.9999278,22.1073011 L6.17027292,25.8660442 C5.62666472,26.1652224 4.9543032,25.9464313 4.66851145,25.3773606 C4.55470768,25.1507536 4.51543682,24.8911934 4.55677846,24.6388629 L5.86112648,16.6777173 L0.335819619,11.0396026 C-0.103968661,10.5908354 -0.112967521,9.85381957 0.315720102,9.39343171 C0.486425762,9.21010286 0.710101484,9.09079613 0.952119664,9.0539816 L8.58790594,7.89246611 L12.0027334,0.649176532 C12.2745375,0.0726449569 12.9413374,-0.164064759 13.4920727,0.120470583 C13.7113783,0.233774049 13.8888884,0.419598634 13.9971222,0.649176532 L17.4119497,7.89246611 L25.0477359,9.0539816 C25.6555084,9.14643274 26.0766123,9.73715392 25.9882977,10.3733943 C25.9531304,10.6267485 25.839162,10.8609012 25.664036,11.0396026 L20.1387291,16.6777173 L21.4430771,24.6388629 C21.5468971,25.2725324 21.1403537,25.8743279 20.535037,25.9830107 C20.2939968,26.0262888 20.0460503,25.9851785 19.8295827,25.8660442 L12.9999278,22.1073011 Z"
				id="Star"
				fill="#DF004D"
			/>
			<polygon
				id="Path"
				fill="#FFFFFF"
				points="11.6666667 17 9 14.4736842 10 13.5263158 11.6666667 15.1052632 16 11 17 11.9473684"
			/>
		</g>
	</svg>
);

export default VoteYes;
