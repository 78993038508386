import * as React from "react";
import styled from "styled-components";
import FanHubLogo from "../../Icons/FanHubLogo";
import colors from "../../../assets/colors";

const DOT_SPACE = 5;

const PrivacyStatement = styled.div`
	font-size: 10px;
	font-family: Radikal;

	color: ${colors.text.disabled};

	width: 100%;
	max-width: 850px;
	text-align: left;

	margin: 50px 0 0;

	> span {
		font-weight: 600;
	}

	@media screen and (max-width: 768px) {
		text-align: center;
		margin: 0 0 50px;
	}
`;

const NavItem = styled.a`
	font-size: 12px;
	font-family: Radikal;
	font-weight: 600;
	text-decoration: none;

	color: ${colors.text.disabled};

	a& {
		cursor: pointer;
		:hover {
			text-decoration: underline;
		}
	}

	margin-right: ${DOT_SPACE * 2}px;
	:last-child {
		margin-right: 0;
	}

	@media screen and (max-width: 768px) {
		font-size: 10px;

		margin-right: ${DOT_SPACE}px;
		:last-child {
			margin-right: 0;
		}

		span& {
			margin-right: 0;
		}
	}
`;

const Dot = styled.div`
	height: 3px;
	width: 3px;
	border-radius: 1.5px;

	background-color: ${colors.text.disabled};

	margin-right: ${2 * DOT_SPACE}px;

	@media screen and (max-width: 768px) {
		display: ${({ show_mobile }) => (show_mobile ? "block" : "none")};
		margin-right: ${DOT_SPACE}px;
	}
`;

const NavsWrapper = styled.div`
	display: flex;

	align-items: center;
	justify-content: flex-start;

	@media screen and (max-width: 768px) {
		flex-wrap: wrap;
		justify-content: center;

		margin-bottom: 20px;
		max-width: 200px;
	}
`;

const LeftGroup = styled.div`
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	flex-direction: column;

	@media screen and (max-width: 768px) {
		align-items: center;
	}
`;

const FanHubLink = styled.a`
	color: unset;

	> svg {
		height: 40px;
		width: 121px;
	}
`;

const FooterWrapper = styled.div`
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: space-between;

	margin: 50px 0 45px;

	@media screen and (max-width: 768px) {
		max-width: calc(100% - 80px);
		margin: 50px auto;

		flex-direction: column;
		align-items: center;
	}
`;

export class Footer extends React.Component {
	render() {
		return (
			<React.Fragment>
				<FooterWrapper>
					<LeftGroup>
						<NavsWrapper>
							<NavItem as="span">Copyright &copy;2019 Netball Australia</NavItem>
							<Dot />
							<NavItem
								href="https://netball.com.au/about-netball-australia/governance/privacy-statement/"
								target="_blank"
								rel="noopener noreferrer"
							>
								Privacy Policy
							</NavItem>
							<Dot show_mobile />
							<NavItem
								href="https://supernetball.com.au/net-points-terms-conditions"
								rel="noopener noreferrer"
								target="_blank"
							>
								Terms &amp; Conditions
							</NavItem>
						</NavsWrapper>
						<PrivacyStatement>
							<span>Privacy Collection Statement</span>: Nissan Motor Co. (Australia)
							Pty Ltd (Nissan) respects your privacy. We will only use the information
							you provide for the purposes of processing and fulfilling your request
							or enquiry and, if you have consented on this form, sending you
							marketing material which may be of interest to you. We may disclose your
							information to an authorised Nissan dealer, our related companies and/or
							third parties associated with us for these purposes. Our Privacy Policy
							contains further details regarding how you can access or correct
							information we hold about you, how you can make a privacy related
							complaint, how that complaint will be dealt with and the extent to which
							your information may be disclosed to overseas recipients.
						</PrivacyStatement>
					</LeftGroup>
					<FanHubLink href="http://www.fanhubmedia.com">
						<FanHubLogo />
					</FanHubLink>
				</FooterWrapper>
			</React.Fragment>
		);
	}
}

export default Footer;
