import * as React from "react";
import styled, { css } from "styled-components";
import SolidArrow from "../../Icons/SolidArrow";
import solid_arrow from "../../../assets/images/solid_arrow.png";
import { PrimaryButton } from "../../atoms/Buttons";
import { PlayerAvatar } from "../../atoms/PlayerAvatar";

export const BaseSelect = styled.select`
	background: url(${solid_arrow}) no-repeat #fff right 19px center;
	border: 1px solid #e5e5e5;
	color: #8294a5;
	font-size: 12px;
	appearance: none;
	border-radius: 4px;
	padding: 0 30px 0 10px;
	height: 50px;
	box-sizing: border-box;
	min-width: 160px;
	position: relative;
	outline: none;
`;

export const Table = styled.div``;

export const TableHeader = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-bottom: 3px solid #e2e2e2;
	color: #8294a5;
	font-size: 12px;
`;

export const TableCell = styled.div`
	text-align: center;
	color: #231F20;
	font-family: Radikal;
	font-weight: bold;
	// flex: ${({ flex }) => flex || "auto"};
	${({ width }) => (width !== undefined ? `width: ${width}` : "")};
	${({ is_empty }) =>
		is_empty
			? css`
					font-size: 18px;
			  `
			: css`
					font-size: 12px;
			  `}
	@media screen and (max-width: 768px) {
		&:last-child {
			width: 120px;
		}
		&:first-child {
			width: calc(100% - 120px);
		}
	}
`;

const HeaderSlimArrow = styled.div`
	//color: currentColor;
	position: absolute;
	left: ${({ is_first }) => (is_first ? "35px" : "50%")};
	width: 10px;
	height: 10px;
`;

const ArrowToTop = styled(HeaderSlimArrow)`
	top: 10%;
	transform: translateX(-50%) rotate(180deg);
`;

const ArrowToBottom = styled(HeaderSlimArrow)`
	bottom: 10%;
	transform: translateX(-50%);
`;

const Tooltip = styled.div`
	bottom: 85%;
	transform: translateY(0);
	width: 0;
	height: 90px;
	opacity: 0;
	left: 0;
	visibility: hidden;
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;

	max-width: 100px;
	padding: 10px 15px;
	border-radius: 5px;

	font-family: Radikal;
	font-size: 10px;
	font-weight: bold;
	text-transform: none;
	letter-spacing: 1px;

	color: white;
	background-color: #df004d;

	transition: opacity 0.2s ease-in;
	&:after {
		content: "";

		position: absolute;
		top: 100%;
		left: 50%;
		transform: translateX(-50%);

		height: 0;
		width: 0;

		margin: 0;
		padding: 0;

		border-style: solid;
		border-width: 6px;
		border-color: transparent;
		border-top-color: #df004d;
	}
`;

const TableHeaderCellWrapper = styled(TableCell)`
	position: relative;
	padding-left: ${({ is_first }) => (is_first ? "15px" : "0")};
	justify-content: ${({ is_first }) => (is_first ? "flex-start" : "center")};
	color: #df004d;
	font-size: 12px;
	user-select: none;
	font-weight: bold;
	height: 80px;
	box-sizing: border-box;
	display: flex;
	align-items: center;
	.show-on-hover {
		display: none;
	}
	${({ no_hover }) =>
		no_hover
			? ""
			: css`
					cursor: pointer;
					&:hover {
						background: linear-gradient(
							180deg,
							#ffffff 0%,
							#fcfdfe 18.36%,
							#f7f0f0 100%
						);
						${Tooltip} {
							height: 60px;
							width: calc(90vw - 65px);
							opacity: 1;
							visibility: visible;
							transform: translateX(-50%);
							left: 50%;
							transition: opacity 0.2s ease-in;
						}
						${({ is_active }) =>
							!is_active
								? css`
										.show-on-hover {
											display: block;
										}
								  `
								: css``}
					}
			  `}
	${({ width }) => (width !== undefined ? `width: ${width}` : "")};
	.logo {
		width: 130px;
	}
	${({ is_active }) =>
		is_active
			? css`
					background: linear-gradient(180deg, #ffffff 0%, #fcfdfe 18.36%, #f7f0f0 100%);
			  `
			: ""}
`;

export const TableHeaderCell = ({
	children,
	is_active,
	is_desc,
	is_first,
	tooltip,
	no_hover,
	...rest
}) => (
	<TableHeaderCellWrapper is_active={is_active} is_first={is_first} no_hover={no_hover} {...rest}>
		<ArrowToTop className="show-on-hover" is_first={is_first}>
			<SolidArrow is_active={false} />
		</ArrowToTop>
		<ArrowToBottom className="show-on-hover" is_first={is_first}>
			<SolidArrow is_active={false} />
		</ArrowToBottom>

		{is_active ? (
			<ArrowToTop is_first={is_first}>
				<SolidArrow is_active={is_desc} />
			</ArrowToTop>
		) : null}
		{children}
		{is_active ? (
			<ArrowToBottom is_first={is_first}>
				<SolidArrow is_active={!is_desc} />
			</ArrowToBottom>
		) : null}
		{tooltip && <Tooltip>{tooltip}</Tooltip>}
	</TableHeaderCellWrapper>
);

export const TableBody = styled.div`
	background: #fff;
`;

export const TableBodyRow = styled.div`
	background: #fff;
	display: flex;
	align-items: center;
	border-bottom: 1px solid #e3dada;
	height: 80px;
	&:nth-child(even) {
		background: #fafafa;
	}
	&:last-child {
		border-bottom: 0;
	}
`;

export const TableBodyCell = styled(TableCell)``;

export const FiltersWrapper = styled.div`
	padding: 20px;
`;

export const FiltersSelectRow = styled.div`
	display: flex;
	align-items: center;
`;

export const FilterSelect = styled(BaseSelect)`
	width: 160px;
	margin-right: 20px;
`;

export const MessageTableBodyCell = styled(TableBodyCell)`
	width: 100%;
	padding: 40px 25px;
`;

export const PlayersTableHeader = styled(TableHeader)`
	width: 100%;
`;

export const FilterButtonsContainer = styled.div`
	display: inline-flex;
	align-items: center;
`;

export const LoadMoreButton = styled(PrimaryButton)`
	margin: 30px auto 0;
	font-size: 14px;
	line-height: 17px;
	display: ${({ is_visible }) => (is_visible ? "block" : "none")};
`;

const PlayerStyled = styled.div`
	display: flex;
	align-items: center;
`;

const PlayerAva = styled.div`
	width: 80px;
	img {
		width: auto;
		height: 80px;
	}
`;

const PlayerLogo = styled.img`
	width: 40px;
	margin-left: 15px;
	margin-right: 10px;
`;

const PlayerText = styled.div`
	text-align: left;
	margin-left: 20px;
	color: #231f20;
	font-family: Radikal;
	font-size: 14px;
	font-weight: 500;
	line-height: 14px;
	p:first-child {
		font-size: 16px;
		font-weight: 900;
		line-height: 16px;
	}
`;

export const Player = ({ player, position }) => {
	const { id, firstName, lastName, squadId } = player || {};
	const { shortName } = position || {};
	const player_img = `${process.env.REACT_APP_MEDIA_URL}/players/${id}.png`;
	const squad_img = `${process.env.REACT_APP_MEDIA_URL}/squads/${squadId}.svg`;

	return (
		<PlayerStyled>
			<PlayerLogo src={squad_img} alt="" />

			<PlayerAva src={player_img} alt={`${firstName} ${lastName}`}>
				<PlayerAvatar player={player} />
			</PlayerAva>

			<PlayerText>
				<p>
					{player.firstName} {player.lastName}
				</p>
				<p>{shortName}</p>
			</PlayerText>
		</PlayerStyled>
	);
};
