import * as React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import colors from "../../../../assets/colors";
import ArrowDown from "../../../Icons/ArrowDown";
import Label from "../Label";

export const StyledSelect = styled.select`
	color: ${colors.text.disabled};
	font-size: 16px;
	font-family: Radikal;
	font-weight: 600;

	background-color: transparent;
	width: 100%;
	height: 100%;
	-webkit-appearance: none;
	-moz-appearance: none;
	outline: none;

	border: none;
	padding: 0 20px;
	margin: 0;

	transition: color 0.2s linear;

	:focus {
		color: ${colors.text.black};
	}

	&:disabled {
		opacity: 0.5;
	}

	&::-ms-expand {
		display: none;
	}
`;

const SelectWrapper = styled.div`
	position: relative;
	box-sizing: border-box;
	padding: 0;
	height: 60px;

	border-radius: 5px;
	border: 1px solid ${({ is_error }) => (is_error ? colors.form.error : colors.form.border)};

	background-color: white;

	display: flex;
	align-items: center;
	justify-content: space-between;

	> svg {
		height: 24px;
		width: 24px;

		position: absolute;
		top: 50%;
		right: 10px;
		transform: translateY(-50%);
		pointer-events: none;
	}

	transition: background-color 0.2s linear;
`;

const SelectFieldWrapper = styled.div`
	width: 100%;
`;

export class Select extends React.Component {
	render() {
		const { label, input, meta, ...rest } = this.props;
		const name = input.name;
		const is_error = meta.touched && meta.error !== undefined;

		return (
			<SelectFieldWrapper>
				<Label htmlFor={name}>
					{label}
					<span className="error">{is_error && ` - ${meta.error}`}</span>
				</Label>
				<SelectWrapper is_error={is_error}>
					<StyledSelect {...rest} {...input} id={name} />
					<ArrowDown />
				</SelectWrapper>
			</SelectFieldWrapper>
		);
	}
}

Select.propTypes = {
	input: PropTypes.object.isRequired,
	meta: PropTypes.object.isRequired,
	label: PropTypes.any
};

export default Select;
