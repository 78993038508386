import styled, { css } from "styled-components";
import { StarIconWrapper } from "./votedFor";

export const CardWrapper = styled.div`
	width: 170px;
	height: 270px;

	position: relative;

	@media screen and (max-width: 1024px) {
		width: 100px;
		height: 160px;
	}

	@media screen and (max-width: 340px) {
		width: 90px;
		height: 150px;
	}

	${({ faded }) =>
		faded &&
		css`
			opacity: 0.5;
		`}

	transition: transform 0.2s ease-out;

	${({ clickable }) =>
		clickable &&
		css`
			cursor: pointer;
			&:hover {
				transform: translateY(-16px);

				/* Change to Vote Yes icon */
				${StarIconWrapper} > svg {
					:first-child {
						display: block;
					}
					:last-child {
						display: none;
					}
				}
			}
		`}
`;

export default CardWrapper;
