import { lighten } from "polished";

export const NISSAN_RED = "#C71444";
const primary = "#DF004D";
export const colors = {
	primary: {
		primary,
		accent: lighten(0.1, primary)
	},
	text: {
		grey: "#646464",
		black: "#231F20",
		disabled: "#B8B8B8"
	},
	buttons: {
		primary,
		disabled: "#E3DADA"
	},
	form: {
		error: primary,
		border: '#E3DADA',
	},
	card: {
		name_bg: "#C3002F",
		pos_bg: "#B60631",
		gradient: {
			from: NISSAN_RED,
			to: "#5C091F"
		}
	}
};

export default colors;
