import styled from "styled-components";
import colors from "../../../../assets/colors";
import { lighten } from "polished";

export const PrimaryButton = styled.button`
	outline: none;
	border: none;
	background-color: ${colors.buttons.primary};
	color: white;
	font-size: 14px;

	height: 40px;
	min-width: 200px;

	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	width: ${({ width }) => width || "auto"};
	text-align: center;
	border-radius: 2px;
	font-family: Radikal;
	text-transform: uppercase;
	font-weight: bold;
	letter-spacing: 1px;

	:hover:not(:disabled) {
		background-color: ${lighten(0.1, colors.buttons.primary)};
	}

	&:disabled {
		background: ${colors.buttons.disabled};
		cursor: default;
	}
`;

export default PrimaryButton;
