import { combineReducers } from "redux";
import {
	FETCH_QUESTIONS_SUCCESS,
	FETCH_QUESTIONS_BEGIN,
	FETCH_QUESTIONS_FAIL
} from "../constants/actionTypes/questions";

const data = (state = {}, action) => {
	switch (action.type) {
		case FETCH_QUESTIONS_SUCCESS:
			if (!action.questions) {
				return state;
			}
			return action.questions;
		default:
			return state;
	}
};

const isFetching = (state = false, action) => {
	switch (action.type) {
		case FETCH_QUESTIONS_BEGIN:
			return true;
		case FETCH_QUESTIONS_SUCCESS:
			return false;
		case FETCH_QUESTIONS_FAIL:
			return false;
		default:
			return state;
	}
};

const checksum = (state = null, action) => {
	switch (action.type) {
		case FETCH_QUESTIONS_SUCCESS:
			if (!action.checksum) {
				return state;
			}
			return action.checksum;
		default:
			return state;
	}
};

const questions = combineReducers({
	data,
	checksum,
	isFetching
});

export default questions;

export const isFetchingQuestions = state => state.questions.isFetching;

export const getQuestionsChecksum = state => state.questions.checksum;
