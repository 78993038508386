import * as React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { getAllPlayersAsOrderedArray } from "../../reducers/players";
import { getAllSquadsAsOrderedArray } from "../../reducers/squads";
import { getAllPositionsAsOrderedArray, getAllPositions } from "../../reducers/positions";
import {
	get,
	map,
	size,
	toPairs,
	chain,
	partial,
	debounce,
	values,
	identity,
	bindAll
} from "lodash";
import { createPlayersFilter } from "../../selectors";
import { isMatchMaxWidth } from "../../utils";
import { StyledSelect } from "../../components/atoms/Form/Select";
import { StyledInput } from "../../components/atoms/Form/Input";
import { Title } from "../../components/atoms/Title";
import TeamOfTheWeek from "../../components/Icons/TeamOfTheWeek";
import {
	Player,
	Table,
	TableBody,
	TableBodyRow,
	TableCell,
	TableHeaderCell,
	LoadMoreButton,
	MessageTableBodyCell,
	TableHeader
} from "../../components/molecules/Table";
import logo from "../../assets/images/net-points/colored/landscape@2x.png";
import arrow from "../../assets/images/solid_arrow.png";

const LAST_ROUND = parseInt(process.env.REACT_APP_LAST_ROUND, 10);

const LeaderboardWrapper = styled.div`
	width: 100%;
	padding: 50px 40px;
	background: #fff;
	box-sizing: border-box;

	@media screen and (max-width: 768px) {
		padding: 20px 0;
	}
`;

const SelectWrapper = styled.div`
	${StyledSelect} {
		border: 1px solid #e3dada;
		border-radius: 5px;
		height: 60px;
		width: 260px;
		background: url(${arrow}) no-repeat right 20px center;
		@media screen and (max-width: 768px) {
			width: 100%;
			padding-right: 35px;
		}
	}
`;
const Filters = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: 20px;
	@media screen and (max-width: 768px) {
		padding: 0 20px;
		display: block;
	}
	> div {
		margin-right: 20px;
		@media screen and (max-width: 768px) {
			margin-right: 0;
		}
		&:last-child {
			margin-right: 0;
		}

		&:first-child {
			width: 100%;
		}
	}

	label {
		color: #b8b8b8;
		font-family: Radikal;
		font-size: 14px;
		font-weight: bold;
		letter-spacing: 1px;
		line-height: 17px;
		margin-bottom: 5px;
		display: block;
	}

	${StyledInput} {
		padding: 0 20px;
		box-sizing: border-box;
		height: 60px;
		border: 1px solid #e3dada;
		border-radius: 5px;
		width: 100%;
	}
`;

const TitleStyled = styled(Title)`
	@media screen and (max-width: 768px) {
		padding: 0 20px;
	}
`;

const SelectFilters = styled.div`
	display: flex;
	align-items: center;

	> div:first-child {
		margin-right: 20px;
	}
	@media screen and (max-width: 768px) {
		justify-content: space-between;
		margin-top: 10px;
		> div {
			flex-basis: 48%;

			&:first-child {
				margin-right: 0;
			}
		}
	}
`;
const stats_list = {
	TOT: {
		name: "Total Net Points",
		field: "stats.totalPoints",
		tooltip: "Total number of points"
	},
	GP: {
		name: "Total Net Points",
		field: "stats.gamesPlayed",
		tooltip: "Number of games played"
	},
	AVG: {
		name: "Average Net Points (season)",
		field: "stats.avgPoints",
		tooltip: "Average Net Points"
	},
	"L3 AVG": {
		name: "3 Round Average Net Points",
		field: "stats.last3Avg",
		tooltip: "Last 3 games average"
	},
	"L5 AVG": {
		name: "5 Round Average Net Points",
		field: "stats.last5Avg",
		tooltip: "Last 5 games average"
	}
};
class LeaderboardComponent extends React.Component {
	default_filters = {
		by_name: "",
		by_position: "",
		by_squad: ""
	};
	stats_pairs_array = toPairs(stats_list);
	initial_sort_field = "stats.scores";
	state = {
		players_show_limit: 20,
		order_by_desc: false,
		sort_by: this.initial_sort_field,
		filters: this.default_filters,
		players_show_limit_step: 20,
		is_mobile: isMatchMaxWidth(768)
	};

	constructor(props) {
		super(props);

		bindAll(this, [
			"onFilterChange",
			"onLoadMore",
			"onChangeSortOrder",
			"orderComparator",
			"onResize"
		]);
	}
	onResizeDebounced = null;

	componentDidMount() {
		const { round } = this.props;

		if (round) {
			const id = parseInt(round.id) || LAST_ROUND;
			this.setState({
				sort_by: `stats.scores.${id}`
			});
		}

		this.onResizeDebounced = debounce(this.onResize, 100);
		window.addEventListener("resize", this.onResizeDebounced);
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.onResizeDebounced);
	}

	componentDidUpdate({ round: prev_round }) {
		const { round } = this.props;
		if (
			(!prev_round && round) ||
			(prev_round &&
				round &&
				prev_round.id !== round.id &&
				this.state.sort_by.includes("stats.scores"))
		) {
			const id = parseInt(round.id) || LAST_ROUND;

			this.setState({
				sort_by: `stats.scores.${id}`
			});
		}
	}

	onResize() {
		const is_mobile = isMatchMaxWidth(768);

		this.setState({
			is_mobile
		});
	}

	get stats_pairs() {
		const pairs = this.stats_pairs_array;
		if (this.state.is_mobile) {
			return [];
		}

		return pairs;
	}

	get players() {
		const { filters, players_show_limit } = this.state;
		const { players } = this.props;

		return chain(players)
			.slice()
			.sort(this.orderComparator)
			.filter(createPlayersFilter(filters))
			.take(this.has_filters ? Number.MAX_SAFE_INTEGER : players_show_limit)
			.value();
	}

	orderComparator(player_one, player_two) {
		const { sort_by, order_by_desc } = this.state;
		let new_order = order_by_desc;

		if (sort_by === "lastName") {
			new_order = !new_order;
		}

		const order = new_order ? -1 : 1;
		const minimal = Number.MAX_SAFE_INTEGER * order;
		const getResult = (condition, min, value) => (condition(value) ? min : value);
		const isZero = val => !val || val === 0;
		const getResultOrMin = partial(getResult, isZero, minimal * -1);
		const a_result = getResultOrMin(get(player_one, sort_by));
		const b_result = getResultOrMin(get(player_two, sort_by));
		const is_bigger = a_result < b_result;
		const is_less = a_result > b_result;

		if (is_bigger) {
			return order;
		}

		if (is_less) {
			return -1 * order;
		}

		return 0;
	}
	onChangeSortOrder({ currentTarget }) {
		const { sortBy } = currentTarget.dataset;
		const { sort_by, order_by_desc } = this.state;
		const default_order = ["stats.adp", "draft_order"].includes(
			sortBy || this.initial_sort_field
		);

		this.setState({
			sort_by: sortBy || this.initial_sort_field,
			order_by_desc: sortBy === sort_by ? !order_by_desc : default_order
		});
	}
	onFilterChange({ target }) {
		const is_checkbox = target instanceof HTMLInputElement && target.type === "checkbox";
		const key = target.name;
		const value = is_checkbox ? target.checked : target.value;
		const { filters } = this.state;

		this.setState({
			filters: {
				...filters,
				[key]: value
			}
		});
	}

	onLoadMore() {
		const { players_show_limit, players_show_limit_step } = this.state;

		this.setState({
			players_show_limit: players_show_limit + players_show_limit_step
		});
	}

	get search_form() {
		const { filters } = this.state;
		const { squads, positions } = this.props;
		return (
			<Filters>
				<div>
					<label htmlFor="search">SEARCH FOR A PLAYER</label>
					<StyledInput
						name="by_name"
						id="search"
						type="text"
						placeholder="Search"
						onChange={this.onFilterChange}
					/>
				</div>
				<SelectFilters>
					<div>
						<label htmlFor="teams">TEAM</label>
						<SelectWrapper>
							<StyledSelect
								name="by_squad"
								value={filters.by_squad}
								onChange={this.onFilterChange}
								id="teams"
							>
								<option value="">All Teams</option>
								{map(squads, ({ id, name }) => (
									<option value={id} key={id}>
										{name}
									</option>
								))}
							</StyledSelect>
						</SelectWrapper>
					</div>
					<div>
						<label htmlFor="position">POSITION</label>
						<SelectWrapper>
							<StyledSelect
								name="by_position"
								id="position"
								value={filters.by_position}
								onChange={this.onFilterChange}
							>
								<option value="">All Positions</option>
								{map(positions, ({ id, fullName }) => (
									<option value={id} key={id}>
										{fullName}
									</option>
								))}
							</StyledSelect>
						</SelectWrapper>
					</div>
				</SelectFilters>
			</Filters>
		);
	}

	get table_head() {
		const { round } = this.props;
		const { sort_by, order_by_desc } = this.state;

		return (
			<TableHeader>
				<TableHeaderCell
					width={"30%"}
					is_first={true}
					is_desc={order_by_desc}
					onClick={this.onChangeSortOrder}
					data-sort-by="lastName"
					is_active={sort_by === "lastName"}
				>
					TEAM
				</TableHeaderCell>
				{this.state.is_mobile ? null : (
					<TableHeaderCell width={"10%"} no_hover={true}>
						&nbsp;
					</TableHeaderCell>
				)}

				{map(this.stats_pairs, ([title, stat]) => (
					<TableHeaderCell
						width={"12%"}
						key={stat.field}
						is_active={sort_by === stat.field}
						is_desc={order_by_desc}
						data-sort-by={stat.field}
						tooltip={stat.tooltip}
						onClick={this.onChangeSortOrder}
					>
						{title}
					</TableHeaderCell>
				))}
				<TableHeaderCell
					width={"12%"}
					is_active={sort_by === `stats.scores.${round.id}`}
					is_desc={order_by_desc}
					data-sort-by={`stats.scores.${round.id}`}
					onClick={this.onChangeSortOrder}
					tooltip={"Net Points scored last game"}
				>
					<img className="logo" src={logo} alt="Nissan Net Points" />
				</TableHeaderCell>
			</TableHeader>
		);
	}

	get table_body() {
		const players = this.players;
		const players_size = size(players);
		const has_players = players_size !== 0;
		const is_all_players_visible = players_size === size(this.props.players);
		const is_load_more_visible = has_players && !is_all_players_visible;
		const is_nothing_found = this.has_filters && !has_players;
		const { players_of_the_week_ids, round, positions_by_id } = this.props;
		if (is_nothing_found) {
			return (
				<TableBody>
					<MessageTableBodyCell>
						Sorry, there are no players who match this search query. Please adjust your
						filters above and try again.
					</MessageTableBodyCell>
				</TableBody>
			);
		}
		return (
			<TableBody>
				{players.map(player => {
					return (
						<TableBodyRow
							className="player-item"
							key={player.id}
							data-player-id={player.id}
						>
							<TableCell is_first={true} width={"30%"}>
								<Player
									player={player}
									position={positions_by_id[player.positions[0]]}
								/>
							</TableCell>
							{this.state.is_mobile ? null : (
								<TableCell width={"10%"}>
									{players_of_the_week_ids.includes(player.id) && (
										<TeamOfTheWeek width={"100px"} />
									)}
								</TableCell>
							)}

							{map(this.stats_pairs, ([title, stat]) => (
								<TableCell
									width={"12%"}
									key={stat.field}
									is_empty={!get(player, stat.field, false)}
								>
									{get(player, stat.field, "— —")}
								</TableCell>
							))}
							<TableCell width={"12%"} is_empty={true}>
								{get(player, `stats.scores.${round.id}`, "— —")}
							</TableCell>
						</TableBodyRow>
					);
				})}
				<LoadMoreButton onClick={this.onLoadMore} is_visible={is_load_more_visible}>
					LOAD MORE
				</LoadMoreButton>
			</TableBody>
		);
	}

	get has_filters() {
		return size(values(this.state.filters).filter(identity));
	}

	render() {
		const { round } = this.props;

		if (!round) {
			return null;
		}
		return (
			<LeaderboardWrapper>
				<TitleStyled>STATS / LEADERBOARD</TitleStyled>

				{this.search_form}
				<Table>
					{this.table_head}
					{this.table_body}
				</Table>
			</LeaderboardWrapper>
		);
	}
}

const mapStateToProps = (state, props) => {
	const options = get(props.round, ["question", "options"], []);
	return {
		players: getAllPlayersAsOrderedArray(state),
		squads: getAllSquadsAsOrderedArray(state),
		positions: getAllPositionsAsOrderedArray(state),
		positions_by_id: getAllPositions(state),
		players_of_the_week_ids: map(options, ({ playerId }) => playerId)
	};
};

export const Leaderboard = connect(mapStateToProps)(LeaderboardComponent);

export default Leaderboard;
