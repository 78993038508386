import styled from "styled-components";

export const PageLayout = styled.div`
	width: 100%;
	max-width: 1300px;
	margin: 0 auto;
	box-sizing: border-box;

	@media screen and (max-width: 1300px) {
		padding: 0 20px;
	}

	@media screen and (max-width: 768px) {
		padding: 0;
	}
`;

export default PageLayout;
