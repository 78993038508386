import React from "react";

export const VoteNo = ({ unit = "em", ...rest } = {}) => (
	<svg viewBox="0 0 26 26" {...rest}>
		<g
			id="Component/Game-Play/Selection/No"
			stroke="none"
			strokeWidth="1"
			fill="none"
			fillRule="evenodd"
		>
			<path
				d="M5.92919376,25.4280022 L12.9999278,21.5365796 L20.0706618,25.4280022 C20.1869992,25.4920291 20.3186783,25.513862 20.4466764,25.4908803 C20.7783603,25.4313274 21.0100553,25.0883555 20.9496558,24.7197047 L19.6043603,16.5086344 L25.3069281,10.6896398 C25.4066157,10.5879169 25.4725221,10.4525097 25.4930461,10.30465 C25.5443904,9.93475195 25.304944,9.59885838 24.9725435,9.54829537 L17.0733204,8.34670726 L13.5448626,0.862392982 C13.4831347,0.731460157 13.3835128,0.627172014 13.2625698,0.564687225 C12.9664478,0.411696901 12.6073782,0.539164331 12.454993,0.862392982 L8.92653516,8.34670726 L1.02731206,9.54829537 C0.897454048,9.56804869 0.776002176,9.63283008 0.681647009,9.73416254 C0.434997512,9.99905107 0.440281755,10.4318359 0.692927479,10.6896398 L6.39549526,16.5086344 L5.05019979,24.7197047 C5.02587816,24.8681529 5.048981,25.0208508 5.11532976,25.1529649 C5.27522564,25.4713508 5.63670094,25.5889774 5.92919376,25.4280022 Z"
				id="Star"
				stroke="#E3DADA"
			/>
		</g>
	</svg>
);

export default VoteNo;
