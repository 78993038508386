import styled from "styled-components";
import colors from "../../assets/colors";

export const ContentPanel = styled.div`
	background-color: white;
	color: ${colors.text.black};
	padding: 50px 40px;
	margin: 40px 0;

	@media screen and (max-width: 768px) {
		padding: 0;
	}
`;

export default ContentPanel;
