import * as React from "react";
import { connect } from "react-redux";
import _ from "lodash";
import styled from "styled-components";
import Title from "../../atoms/Title";
import { WeeklyPrizeSection } from "../PrizeSection";

const WinnerName = styled(Title)`
	font-size: 40px;

	@media screen and (max-width: 768px) {
		font-size: 30px;
	}
`;

const WinnerWrapper = styled.div`
	margin-bottom: 40px;

	@media screen and (max-width: 768px) {
		margin: 40px 20px 30px;
	}
`;

const NoWinnerName = styled(Title)`
	font-size: 20px;
`;

const WinnerSectionComponent = ({ round_id, winner_name }) => {
	return (
		<WinnerWrapper>
			{winner_name ? (
				<React.Fragment>
					<Title>Round {round_id} Winner</Title>
					<WinnerName>{winner_name}</WinnerName>
				</React.Fragment>
			) : (
				<NoWinnerName>
					Voting for Round {round_id} is now closed. Be sure to check back for the weekly winner announcement! Voting for the next round will commence next Monday.
				</NoWinnerName>
			)}

			<WeeklyPrizeSection />
		</WinnerWrapper>
	);
};

const mapStateToProps = (state, props) => {
	const questions = _.keyBy(state.questions.data, 'id');

	return {
		winner_name: _.get(questions, [props.round_id, "prizeWinner"], "")
	};
};
const mapDispatchToProps = {};

export const WinnerSection = connect(
	mapStateToProps,
	mapDispatchToProps
)(WinnerSectionComponent);

export default WinnerSection;
