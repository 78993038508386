import * as React from "react";
import _ from "lodash";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import AvatarSection from "./AvatarSection";
import DetailsSection from "./DetailsSection";
import CardWrapper from "./CardWrapper";
import Types from "../../../constants/types";
import NameSection, { NamesWrapper, PositionWrapper } from "./NameSection";
import StatsSection from "./StatsSection";
import { BackTriangle } from "./BackTriangle";
import PlayerAvatar from "../PlayerAvatar";
import NetPointsScore from "./NetPoints/score";
import VotedFor from "./votedFor";
import NetPointsWrapper, { ScoreLabel } from "./NetPoints/wrapper";
import votingActions from "../../../actions/voting";

export class PlayerCardComponent extends React.Component {
	static defaultProps = {
		clickable: true,
		selected: false,
		selection_active: false,
		winner: false,
		player: {
			id: null
		}
	};
	constructor(props) {
		super(props);

		_.bindAll(this, ["voteForPlayer"]);
	}
	voteForPlayer() {
		const { voteForPlayer, clickable, player } = this.props;
		if (clickable) {
			voteForPlayer(player);
		}
	}
	render() {
		const {
			player,
			position,
			net_points,
			selected,
			clickable,
			selection_active,
			winner
		} = this.props;

		if (!player || !player.id) {
			return null;
		}

		return (
			<CardWrapper
				onClick={this.voteForPlayer}
				clickable={clickable}
				faded={selection_active && !selected}
			>
				<AvatarSection>
					<PlayerAvatar player={player} />
					<BackTriangle />
				</AvatarSection>
				<DetailsSection>
					<NameSection>
						<NamesWrapper>
							<div>{_.get(player, "firstName", "")}</div>
							<div>{_.get(player, "lastName", "")}</div>
						</NamesWrapper>
						<PositionWrapper>{position}</PositionWrapper>
					</NameSection>
					<StatsSection>
						<NetPointsWrapper>
							<NetPointsScore score={net_points} />
							<ScoreLabel>Nissan Net Points</ScoreLabel>
						</NetPointsWrapper>
						<VotedFor selected={selected} clickable={clickable} winner={winner} />
					</StatsSection>
				</DetailsSection>
			</CardWrapper>
		);
	}
}

PlayerCardComponent.propTypes = {
	player: Types.player,
	net_points: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	position: PropTypes.string.isRequired,
	selection_active: PropTypes.bool,
	selected: PropTypes.bool,
	clickable: PropTypes.bool
};

const mapDispatchToProps = {
	voteForPlayer: votingActions.voteForPlayer
};

const PlayerCard = connect(
	null,
	mapDispatchToProps
)(PlayerCardComponent);

export default PlayerCard;
