import * as React from 'react';

export const SolidArrow = ({ is_active } = {}) => (
	<svg viewBox="0 0 10 5">
		<g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<g id="Component/Leaderboard/Header" transform="translate(-1155.000000, -67.000000)" fill={is_active ? '#DF004D' : '#E3DADA'}>
				<g id="Net-Points" transform="translate(1100.000000, 0.000000)">
					<polygon id="Shape" points="55 67 60 72 65 67" />
				</g>
			</g>
		</g>
	</svg>
);

export default SolidArrow;