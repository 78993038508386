import React from "react";

export const Twitter = ({ unit = "em", is_active, ...rest } = {}) => (
	<svg width="60px" height="60px" viewBox="0 0 60 60" {...rest}>
		<g id="Desktop---[Competition]-Onboarding" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<g id="Global---Landing-(Form-Submit)-[D]" transform="translate(-567.000000, -1250.000000)">
				<g id="Enrty" transform="translate(115.000000, 912.000000)">
					<g id="Form" transform="translate(0.000000, 0.000000)">
						<g id="Social" transform="translate(452.000000, 338.000000)">
							<g id="Twitter">
								<g id="UI/Icon/Twitter/White">
									<path d="M45,20.46875 C44.1497419,21.744798 43.1218334,22.8320268 41.9162437,23.7304688 C41.9289341,23.9127613 41.9352792,24.1861961 41.9352792,24.5507813 C41.9352792,26.243498 41.6941648,27.9329343 41.2119289,29.6191406 C40.729693,31.305347 39.9968323,32.9231693 39.0133249,34.4726562 C38.0298174,36.0221432 36.8591438,37.3925722 35.501269,38.5839844 C34.1433942,39.7753966 32.5063548,40.7259079 30.5901015,41.4355469 C28.6738483,42.1451858 26.6243764,42.5 24.4416244,42.5 C21.0025209,42.5 17.8553442,41.555999 15,39.6679687 C15.4441647,39.7200523 15.9390836,39.7460937 16.4847716,39.7460937 C19.3401158,39.7460937 21.8845066,38.8476652 24.1180203,37.0507813 C22.7855263,37.0247395 21.5926449,36.6048218 20.5393401,35.7910156 C19.4860353,34.9772095 18.7626923,33.9388084 18.3692893,32.6757813 C18.7880732,32.7408857 19.1751251,32.7734375 19.5304569,32.7734375 C20.0761449,32.7734375 20.6154796,32.7018236 21.1484772,32.5585937 C19.7271503,32.2591131 18.5501316,31.5332089 17.6173858,30.3808594 C16.68464,29.2285099 16.2182741,27.8906326 16.2182741,26.3671875 L16.2182741,26.2890625 C17.0812226,26.7838566 18.0076093,27.0507811 18.9974619,27.0898437 C18.1598943,26.5169242 17.4936573,25.7682338 16.998731,24.84375 C16.5038046,23.9192662 16.2563452,22.9166721 16.2563452,21.8359375 C16.2563452,20.6900984 16.5355302,19.6289111 17.0939086,18.6523437 C18.6294493,20.5924576 20.4980854,22.1451765 22.6998731,23.3105469 C24.9016608,24.4759173 27.2588707,25.1236973 29.7715736,25.2539062 C29.6700503,24.7591121 29.6192893,24.2773461 29.6192893,23.8085937 C29.6192893,22.0637934 30.2189026,20.576178 31.4181472,19.3457031 C32.6173918,18.1152282 34.0672504,17.5 35.7677665,17.5 C37.5444251,17.5 39.0418721,18.1640559 40.2601523,19.4921875 C41.6434079,19.2187486 42.9441563,18.7109412 44.1624365,17.96875 C43.6928911,19.4661533 42.7918848,20.6249959 41.4593909,21.4453125 C42.6395998,21.3151035 43.8197911,20.9895859 45,20.46875 Z" id="Twitter-Icon" fill="#DF004D" fillRule="evenodd" />
									<rect id="Rectangle" stroke="#DF004D" strokeWidth="1" x="0.5" y="0.5" width="59" height="59" />
								</g>
							</g>
						</g>
					</g>
				</g>
			</g>
		</g>
	</svg>
);

export default Twitter;