import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";

import voting from "./voting";
import squads from "./squads";
import questions from "./questions";
import players from "./players";
import rounds from "./rounds";
import positions from "./positions";

export default combineReducers({
	voting,
	form: formReducer,
	squads,
	questions,
	players,
	rounds,
	positions
});
