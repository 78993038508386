import React from "react";

export const BackTriangle = ({ unit = "em", ...rest } = {}) => (
	<svg
		viewBox="0 0 170 160"
		height="160"
		width="170"
		version="1.1"
		xmlns="http://www.w3.org/2000/svg"
	>
		<polygon points="0 160,170 160,170 0,0 85" fill="url(#triangeGradient)" />
	</svg>
);

export default BackTriangle;
