//@flow
import * as React from "react";
import styled, { css } from "styled-components";
import colors from "../../../assets/colors";
import { isString } from 'lodash';

const RoundItemWrapper = styled.button`
	height: 30px;
	width: 30px;

	font-family: Radikal;
	font-size: 14px;
	font-weight: 600;

	margin: 0;
	padding: 0;
	padding-bottom: 2px;
	outline: none;
	border: none;
	border-radius: 50%;
	box-sizing: border-box;

	display: flex;
	justify-content: center;
	align-items: center;

	cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};
	user-select: none;

	&.selected {
		background: ${colors.primary.primary};
		color: #fff;
		opacity: 1;
		:hover:not(:disabled) {
			background: ${colors.primary.accent};
		}
	}
	${({ disabled }) =>
		disabled
			? css`
					color: ${colors.text.disabled};
					background-color: ${colors.text.black};
					> span {
						opacity: 0.5;
					}
			  `
			: css`
					&.selectable {
						border: 1px solid ${colors.primary.primary};
						background: transparent;
						color: ${colors.primary.primary};
						&:hover:not(:disabled) {
							background: ${colors.primary.primary};
							color: white;
						}
					}
			  `}
`;

const FlexWrapper = styled.div`
	flex: 1 0 0px;
	max-width: 50px;

	display: flex;
	justify-content: center;
`;

export const RoundSelectorItem = ({
	selected_round,
	round_number,
	round_status,
	handleClick,
	selected_round_index,
	disabled,
	round_alias,
	for_publish
}) => {
	const class_name =
		selected_round === round_number ? "selected" : (for_publish || isString(round_number)) ? "selectable" : "";
	return (
		<FlexWrapper>
			<RoundItemWrapper
				key={round_number}
				className={class_name}
				onClick={disabled ? undefined : () => handleClick(round_number)}
				selected_round={selected_round}
				index={selected_round_index}
				disabled={disabled}
			>
				<span>{round_alias || round_number}</span>
			</RoundItemWrapper>
		</FlexWrapper>
	);
};

RoundSelectorItem.defaultProps = {
	round_number: 1,
	selected_round: 1,
	round_status: "active"
};
export default RoundSelectorItem;
