// @flow
import * as React from "react";
import styled, { css } from "styled-components";
import _ from "lodash";
import colors from "../../../assets/colors";
import ArrowRightUnstyled from "../../Icons/ArrowRight";
import ArrowLeftUnstyled from "../../Icons/ArrowLeft";
import RoundSelectorItem from "./RoundSelectorItem";

const Wrapper = styled.div`
	width: 100%;
	padding: 12px 0;
	margin: 60px 0 8px 0;
	@media screen and (max-width: 768px) {
		margin: 60px 0 80px 0;
	}
`;
const Inner = styled.div`
	height: 36px;
	width: 100%;
	box-sizing: border-box;
	padding: 0 0px;
	position: relative;

	display: flex;
	align-items: center;
`;
const Mask = styled.div`
	width: 100%;
	height: 36px;
	overflow-x: scroll;
	overflow-y: hidden;
	overflow: -moz-scrollbars-none;
	-ms-overflow-style: none;
	::-webkit-scrollbar {
		height: 0 !important;
		width: 0 !important;
		display: none !important;
	}
	flex: 1 0 0px;
`;
const Track = styled.div`
	min-width: ${({ length }) => length * 36}px;
	height: 36px;
	white-space: nowrap;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;

	::-webkit-scrollbar {
		height: 0 !important;
		width: 0 !important;
		display: none !important;
	}
`;

const ARROW_STYLES = css`
	height: 30px;
	width: 30px;
	fill: white;

	flex: 0 0 auto;

	cursor: pointer;
	${({ disabled }) =>
		disabled &&
		`
		cursor: default;
		fill: ${colors.buttons.disabled};
	`};

	@media screen and (min-width: 1025px) {
		display: none;
	}
`;

const ArrowLeft = styled(ArrowLeftUnstyled)`
	${ARROW_STYLES}
`;
const ArrowRight = styled(ArrowRightUnstyled)`
	${ARROW_STYLES}
`;

const getActiveChildHalfWayScrollPosition = scrollContainer => {
	const child = scrollContainer.querySelector(".selected");

	if (child) {
		return (
			child.offsetLeft - scrollContainer.clientWidth / 2 + child.clientWidth / 2 - 30
		); /* account for the left arrow */
	}

	return 0;
};

export class RoundSelector extends React.Component {
	static defaultProps = {
		show_all_button: false,
		round_aliases: {}
	};
	constructor() {
		super();
		_.bindAll(this, [
			"setPositionToActiveRoundCentre",
			"setNextScrollPosition",
			"setPreviousScrollPosition",
			"onResize",
			"setRound"
		]);
	}

	componentDidMount() {
		if (!this.onResizeDebounce) {
			this.onResizeDebounce = _.debounce(this.onResize, 300);
		}
		window.addEventListener("resize", this.onResizeDebounce);
	}

	componentDidUpdate(prev_props) {
		if (prev_props.selected !== this.props.selected) {
			this.setPositionToActiveRoundCentre();
		}
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.onResizeDebounce);
	}

	roundsOverflowContainer;
	onResizeDebounce = null;

	onResize() {
		this.setPositionToActiveRoundCentre();
	}

	get next_round() {
		const { selected, show_all_button } = this.props;

		if (selected !== undefined) {
			if (show_all_button && selected === 0) {
				return this.first_round_id;
			}
			return selected + 1;
		}
		return undefined;
	}

	get prev_round() {
		const { selected, show_all_button } = this.props;

		if (selected !== undefined) {
			if (show_all_button && selected === this.first_round_id) {
				return 0;
			}
			return selected - 1;
		}
		return undefined;
	}

	get first_round_id() {
		const { rounds } = this.props;

		return _.get(_.first(rounds), "id", 0);
	}

	get last_round_id() {
		const { rounds } = this.props;

		return _.get(_.last(rounds), "id", 0);
	}

	inRange(value) {
		const { rounds, show_all_button } = this.props;
		let is_all = value === 0 && show_all_button;

		const enabled_round = _.find(
			rounds,
			({ disabled = false, id }) => !disabled && id === value
		);

		return value !== undefined && (enabled_round !== undefined || is_all);
	}

	setNextScrollPosition() {
		const next = this.next_round;

		if (next !== undefined && this.inRange(next)) {
			this.setRound(next);
		}
	}

	setPreviousScrollPosition() {
		const prev = this.prev_round;

		if (prev !== undefined && this.inRange(prev)) {
			this.setRound(prev);
		}
	}

	setPositionToActiveRoundCentre() {
		if (this.roundsOverflowContainer) {
			const scroll_offset = getActiveChildHalfWayScrollPosition(this.roundsOverflowContainer);
			this.roundsOverflowContainer.scrollLeft = scroll_offset;
		}
	}

	setRound(id) {
		const { handleClick } = this.props;

		handleClick(id, this.setPositionToActiveRoundCentre);
	}

	render() {
		const { rounds, selected, round_aliases = {}, show_all_button } = this.props;
		const first_round_id = show_all_button ? 0 : _.get(rounds, [0, "id"], 0);
		const selected_index = _.findIndex(rounds, function(r) {
			return r.id === selected;
		});
		if (_.isEmpty(rounds)) {
			return null;
		}

		const length = rounds.length + (show_all_button ? 1 : 0);

		return (
			<Wrapper>
				<Inner>
					<ArrowLeft
						onClick={this.setPreviousScrollPosition}
						disabled={!this.inRange(this.prev_round)}
					/>
					<Mask ref={x => (this.roundsOverflowContainer = x)}>
						<Track length={length}>
							{show_all_button && (
								<RoundSelectorItem
									round_status={"active"}
									key={0}
									is_first={true}
									selected_round_index={0}
									round_number={0}
									selected_round={selected}
									handleClick={this.setRound}
									round_alias={"All"}
									disabled={false}
									for_publish
								/>
							)}
							{rounds.map(round => {
								const for_publish = _.get(round, "question.forPublish", false);
								return (
									<RoundSelectorItem
										round_status={round.status}
										key={round.id}
										is_first={first_round_id === round.id}
										selected_round_index={
											selected_index + (show_all_button ? 1 : 0)
										}
										round_number={round.id}
										selected_round={selected}
										handleClick={this.setRound}
										round_alias={round_aliases[round.id]}
										disabled={round.disabled || false}
										for_publish={for_publish}
									/>
								);
							})}
						</Track>
					</Mask>
					<ArrowRight
						onClick={this.setNextScrollPosition}
						disabled={!this.inRange(this.next_round)}
					/>
				</Inner>
			</Wrapper>
		);
	}
}

export default RoundSelector;
