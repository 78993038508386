import styled from "styled-components";
import colors from "../../../assets/colors";

export const NameSection = styled.div`
	display: flex;
	align-items: center;
	height: 50px;
	width: 100%;
	background-color: ${colors.card.name_bg};
	color: white;
	font-weight: 600;

	text-transform: uppercase;

	@media screen and (max-width: 1024px) {
		height: 30px;
	}
`;

export const NamesWrapper = styled.div`
	flex: 1 1 0;
	display: flex;
	flex-direction: column;
	padding: 0 0 0 10px;
	box-sizing: border-box;

	font-weight: 700;

	width: 100px;
	font-size: 12px;

	@media screen and (max-width: 1024px) {
		width: 60px;
		padding-left: 5px;
		font-size: 8px;
	}

	> div {
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}
`;

export const PositionWrapper = styled.div`
	height: 100%;
	flex: 0 0 50px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	font-size: 20px;
	font-weight: 700;

	background-color: ${colors.card.pos_bg};

	@media screen and (max-width: 1024px) {
		flex-basis: 30px;
		font-size: 12px;
	}
`;

export default NameSection;
