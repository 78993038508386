import {
	FETCH_QUESTIONS_BEGIN,
	FETCH_QUESTIONS_SUCCESS,
	FETCH_QUESTIONS_FAIL
} from "../constants/actionTypes/questions";
import { getQuestionsChecksum, isFetchingQuestions } from "../reducers/questions";

function fetchQuestionsBegin() {
	return {
		type: FETCH_QUESTIONS_BEGIN
	};
}

function fetchQuestionsSuccess(questions, checksum) {
	return {
		type: FETCH_QUESTIONS_SUCCESS,
		questions,
		checksum
	};
}

function fetchQuestionsFail(error) {
	return {
		type: FETCH_QUESTIONS_FAIL,
		error
	};
}

function fetchQuestions() {
	return async (dispatch, getState, { api }) => {
		if (isFetchingQuestions(getState())) {
			return false;
		}
		dispatch(fetchQuestionsBegin());
		try {
			const checksums = await api.checksums.fetchChecksums();
			const questionsChecksum = checksums.data.polling;
			const isDataStale = questionsChecksum !== getQuestionsChecksum(getState());
			if (!isDataStale) {
				return dispatch(fetchQuestionsSuccess());
			}
			const questions = await api.questions.fetchQuestions();
			return dispatch(fetchQuestionsSuccess(questions.data, questionsChecksum));
		} catch (error) {
			console.error(error);
			// if (sentryClient) {
			// 	sentryClient.captureException(error, {
			// 		extra: {
			// 			failedAction: "fetchQuestions"
			// 		}
			// 	});
			// }
			return dispatch(fetchQuestionsFail(error));
		}
	};
}

export default {
	fetchQuestions
};
