import React from "react";

export const FanHubLogo = ({ unit = "em", ...rest } = {}) => (
	<svg
		viewBox="0 0 121 40"
		version="1.1"
		xmlns="http://www.w3.org/2000/svg"
		xmlnsXlink="http://www.w3.org/1999/xlink"
		{...rest}
	>
		<title>FanHub Media</title>
		<g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" opacity="0.4">
			<g id="Component/Footer" transform="translate(-1179.000000, -30.000000)" fill="#FFFFFF">
				<g id="Asstes/Logo/fanhub-rev" transform="translate(1179.000000, 30.000000)">
					<g id="FHLogo-RGB-SVG-White">
						<g id="Group" transform="translate(55.372881, 0.000000)" fillRule="nonzero">
							<path
								d="M52.8323941,19.9358974 L49.6228178,19.9358974 L49.6228178,23.9025641 L52.8323941,23.9025641 C53.8039831,23.9025641 54.4218008,23.2 54.4218008,21.9205128 C54.4218008,20.6794872 53.8065466,19.9358974 52.8323941,19.9358974"
								id="Path"
							/>
							<path
								d="M54.0654661,13.7948718 C54.0654661,12.5769231 53.5143008,11.8564103 52.6708898,11.8564103 L49.6228178,11.8564103 L49.6228178,15.8230769 L52.6708898,15.8230769 C53.5143008,15.8230769 54.0654661,15.1025641 54.0654661,13.7948718"
								id="Path"
							/>
							<path
								d="M54.6166314,28.2717949 L43.4907839,28.2717949 L43.4907839,6.63333333 L54.2269703,6.63333333 C58.5722034,6.63333333 60.2923517,9.52051282 60.2923517,12.1487179 C60.2923517,14.8410256 58.7670339,16.625641 56.6931144,17.1128205 C58.964428,17.4692308 60.65125,19.6435897 60.65125,22.4 C60.6486864,25.4512821 58.8644492,28.2717949 54.6166314,28.2717949 L54.6166314,28.2717949 Z M42.0885169,20.3230769 C42.0885169,25.5794872 38.5533686,28.6589744 33.1058051,28.6589744 C27.6890042,28.6589744 24.1205297,25.5769231 24.1205297,20.3538462 L24.1205297,6.63076923 L30.3166525,6.63076923 L30.3166525,20.3846154 C30.3166525,22.1692308 31.2882415,23.2717949 33.1058051,23.2717949 C34.9208051,23.2717949 35.8949576,22.1692308 35.8949576,20.3846154 L35.8949576,6.63076923 L42.0885169,6.63076923 L42.0885169,20.3230769 Z M22.7182627,28.2717949 L16.5887924,28.2717949 L16.5887924,19.8051282 L11.1412288,19.8051282 L11.1412288,28.2717949 L5.01175847,28.2717949 L5.01175847,6.63333333 L11.1412288,6.63333333 L11.1412288,14.4512821 L16.5887924,14.4512821 L16.5887924,6.63333333 L22.7182627,6.63333333 L22.7182627,28.2717949 Z M61.7715254,0.143589744 L3.88635593,0.143589744 C1.88934322,0.143589744 0.253792373,1.77948718 0.253792373,3.77692308 L0.253792373,39.8871795 L6.28072034,34.3435897 L61.774089,34.3435897 C63.7711017,34.3435897 65.4066525,32.7076923 65.4066525,30.7102564 L65.4066525,3.77692308 C65.4066525,1.77948718 63.7711017,0.143589744 61.7715254,0.143589744 L61.7715254,0.143589744 Z"
								id="Shape"
							/>
						</g>
						<polygon
							id="Path"
							fillRule="nonzero"
							points="0.133305085 6.63333333 0.133305085 28.2717949 6.26277542 28.2717949 6.26277542 20 13.5586653 20 13.5586653 14.6461538 6.26277542 14.6461538 6.26277542 11.9871795 13.786822 11.9871795 13.786822 6.63333333"
						/>
						<path
							d="M21.0288771,20.1282051 L22.6516102,12.9589744 L24.2410169,20.1282051 L21.0288771,20.1282051 Z M18.9190678,6.63333333 L12.5614407,28.2717949 L19.2113136,28.2717949 L19.8265678,25.4820513 L25.4381992,25.4820513 L26.0534534,28.2717949 L32.7033263,28.2717949 L26.3456992,6.63333333 L18.9190678,6.63333333 Z"
							id="Shape"
							fillRule="nonzero"
						/>
						<polygon
							id="Path"
							fillRule="nonzero"
							points="45.1801695 15.9769231 39.8607839 6.63333333 33.6338983 6.63333333 33.6338983 28.2717949 39.7659322 28.2717949 39.7659322 18.2794872 45.5057415 28.2717949 51.3122034 28.2717949 51.3122034 6.63333333 45.1801695 6.63333333"
						/>
					</g>
				</g>
			</g>
		</g>
	</svg>
);

export default FanHubLogo;
