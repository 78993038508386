import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import App from "./App";
import "./index.css";
import "./assets/fonts.css";
import * as serviceWorker from "./serviceWorker";

import configureStore from "./store";
import rootReducer from "./reducers";
import api from "./api";

try {
	const store = configureStore({ rootReducer, api });

	ReactDOM.render(
		<Provider store={store}>
			<App />
		</Provider>,
		document.getElementById("root")
	);

	serviceWorker.unregister();
} catch (error) {
	console.error("Error captured and logged:", error);
}
