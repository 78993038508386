import * as React from "react";
import styled from "styled-components";
import colors from "../../../assets/colors";
import NissanLogo from "../../../assets/images/NissanLogo.svg";
import CentreLogos from "./centreLogos";

const BackLink = styled.a`
	margin: 40px 0 0 0;

	color: ${colors.text.grey};
	font-family: Radikal;
	font-weight: 600;
	font-size: 12px;
	text-transform: uppercase;
	text-decoration: none;

	cursor: pointer;

	:hover {
		text-decoration: underline;
	}

	@media screen and (max-width: 768px) {
		> span {
			display: none;
		}
	}

	position: absolute;
	top: 0;
	left: 0;
`;

const NissanLogoImg = styled.img`
	background-color: #fff;
	width: 200px;

	position: absolute;
	top: 0;
	right: 0;
	margin: 24px 0 0 0;
	@media screen and (max-width: 1024px) {
		width: 125px;
	}
	@media screen and (max-width: 768px) {
		width: 75px;
	}
`;

const HeaderWrapper = styled.div`
	position: relative;

	display: flex;
	align-items: flex-start;
	justify-content: center;

	margin: 0px 0px 20px 0px;

	@media screen and (max-width: 768px) {
		margin: 0px 10px 20px;
	}
`;

export class Header extends React.Component {
	render() {
		return (
			<HeaderWrapper>
				<BackLink href="https://supernetball.com.au/">
					&lt; Back <span>to Super Netball</span>
				</BackLink>
				<CentreLogos />
				<NissanLogoImg alt="Nissan" src={NissanLogo} />
			</HeaderWrapper>
		);
	}
}

export default Header;
