import React from "react";
import styled, { css } from "styled-components";
import _ from "lodash";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { compose } from "redux";
import FormUnstyled, { Input, Select, CheckBox } from "../../atoms/Form";
import { PrimaryButton } from "../../atoms/Buttons";
import HelpTooltip, { TooltipWrapper } from "../../atoms/HelpTooltip";
import SelectedPlayer from "../../atoms/SelectedPlayer";
import validate from "../../atoms/Form/utils/validate";
import voting from "../../../actions/voting";
import { getAllSquadsAsOrderedArray } from "../../../reducers/squads";
import { EMAIL_ALREADY_USED_ERROR } from "../../../utils/apiErrors";

const DEFAULT_TEXT = (
	<React.Fragment>
		<p>Select a player from the Team of the Round above and tell us why you selected her.</p>
		<p>Then fill in your details to submit your vote for your chance to win a prize!</p>
	</React.Fragment>
);

const getTooltipText = (disabled, season_started) => {
	if (disabled) {
		return season_started
			? "Voting opens the Monday evening after each round."
			: "Voting opens at the end of Round 7";
	}
	return DEFAULT_TEXT;
};

const emailExists = already_used =>
	already_used ? "Email has already been used this week" : undefined;

const Form = styled(FormUnstyled)`
	position: relative;

	padding: 50px 40px;
	${({ disabled }) =>
		disabled &&
		css`
			> div:not(${TooltipWrapper}) {
				opacity: 0.5;
			}
		`}
	@media screen and (max-width: 768px) {
		padding: 40px 20px;
	}
`;

class VotingFormComponent extends React.Component {
	static defaultProps = {
		disabled: false,
		season_started: true
	};

	constructor(props) {
		super(props);

		_.bindAll(this, ["clearSubmitError"]);
	}

	clearSubmitError() {
		this.props.clearSubmitError();
	}

	render() {
		const {
			handleSubmit,
			selected_player,
			button_disabled,
			disabled,
			season_started,
			email_used
		} = this.props;

		return (
			<Form onSubmit={handleSubmit} disabled={disabled}>
				<TooltipWrapper>
					<HelpTooltip text={getTooltipText(disabled, season_started)} />
				</TooltipWrapper>
				<SelectedPlayer player={selected_player} />
				<Field
					name="first_name"
					component={Input}
					type="input"
					placeholder="First Name"
					label="First Name*"
					validate={validate.required}
					disabled={disabled}
				/>
				<Field
					name="last_name"
					component={Input}
					type="input"
					placeholder="Surname"
					label="Surname*"
					validate={validate.required}
					disabled={disabled}
				/>
				<Field
					name="email"
					component={Input}
					type="email"
					placeholder="Email"
					label="Email Address*"
					validate={[validate.required, validate.email]}
					error={emailExists(email_used)}
					disabled={disabled}
					onChange={this.clearSubmitError}
				/>
				<Field
					name="birthday"
					component={Input}
					type="date"
					placeholder="DD / MM / YYYY"
					label="Date of Birth*"
					validate={[validate.required, validate.date]}
					is_calendar
					disabled={disabled}
				/>
				<Field
					name="supported_team"
					component={Select}
					label="Favourite Team"
					disabled={disabled}
				>
					<option value="">Select a team</option>
					{_.map(this.props.squads, squad => {
						return (
							<option value={squad.id} key={squad.id}>
								{squad.name}
							</option>
						);
					})}
				</Field>
				<Field
					name="reason"
					component={Input}
					as="textarea"
					placeholder=""
					label="In 25 words or less, tell us why they are your Game Changer"
					disabled={disabled}
				/>
				<Field
					name="termsAndConditions"
					component={CheckBox}
					text={
						<React.Fragment>
							I accept the Terms and Conditions. You can view them{" "}
							<a
								href="https://supernetball.com.au/net-points-terms-conditions"
								target="_blank"
								rel="noopener noreferrer"
							>
								here
							</a>
							*.
						</React.Fragment>
					}
					validate={validate.required}
					disabled={disabled}
				/>
				<Field
					name="sponsor_opt_in"
					component={CheckBox}
					text="Yes, I would like to be kept informed of future news, events and offers from Nissan."
					disabled={disabled}
				/>
				<Field
					name="email_opt_in"
					component={CheckBox}
					text="Yes, I would like to be kept informed of future news, events and offers from Netball Australia."
					disabled={disabled}
				/>
				<div>
					<PrimaryButton type="submit" disabled={button_disabled}>
						Submit
					</PrimaryButton>
				</div>
			</Form>
		);
	}
}

const mapStateToProps = (state, props) => {
	const player = state.voting.player;
	const form_errors = _.get(state.form, "voting.syncErrors");
	return {
		selected_player: player ? player : undefined,
		button_disabled:
			_.isEmpty(player) ||
			!_.isEmpty(form_errors) ||
			state.voting.isSubmitting ||
			props.disabled,
		squads: getAllSquadsAsOrderedArray(state),
		email_used: _.get(state.voting.error, "text") === EMAIL_ALREADY_USED_ERROR
	};
};

const mapDispatchToProps = {
	clearSubmitError: voting.clearErrors
};

export const VotingForm = compose(
	reduxForm({
		form: "voting",
		initialValues: {
			email_opt_in: false,
			sponsor_opt_in: false
		}
	}),
	connect(
		mapStateToProps,
		mapDispatchToProps
	)
)(VotingFormComponent);

export default VotingForm;
