import * as React from "react";
import styled from "styled-components";
import VoteYes from "../../Icons/VoteYes";
import VoteNo from "../../Icons/VoteNo";

export const StarIconWrapper = styled.div`
	flex: 0 0 auto;
	display: flex;
	align-items: center;
	justify-content: center;
	> svg {
		height: 26px;
		width: 26px;

		@media screen and (max-width: 1024px) {
			height: 20px;
			width: 20px;
		}
	}

	/* Vote No icon */
	> svg:last-of-type {
		/* Show only when the player is clickable and they aren't selected */
		display: ${({ selected, clickable }) => (clickable && !selected ? "block" : "none")};
	}
	/* Vote Yes icon */
	> svg:first-of-type {
		/* Show only when the player is clickable and they aren't selected */
		display: ${({ selected, clickable, winner }) =>
			(clickable && selected) || (!clickable && winner) ? "block" : "none"};
	}
`;

export const VotedFor = ({ selected, winner, clickable }) => {
	return (
		<StarIconWrapper selected={selected} winner={winner} clickable={clickable}>
			<VoteYes />
			<VoteNo />
		</StarIconWrapper>
	);
};

export default VotedFor;
