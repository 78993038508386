import styled from "styled-components";
import colors from "../../../assets/colors";

export const Title = styled.h3`
	font-family: Radikal;
	font-weight: 600;
	text-transform: uppercase;
	font-size: 14px;
	color: ${colors.primary.primary};

	margin: 0 0 30px;

	@media screen and (max-width: 768px) {
		margin-bottom: 20px;
	}
`;

export default Title;
