import _ from "lodash";
export const EMAIL_ALREADY_USED_ERROR = "You have already saved sweepstakes for current poll";
/**
 * Errors which should be ignored
 */
export const IGNORED_ERRORS = [EMAIL_ALREADY_USED_ERROR];

/**
 * Gets the error message to display above the voting form
 */
export const getFormErrorText = (error = {}) => {
	const error_msg = _.get(error, "text");
	if (error_msg) {
		if (_.includes(IGNORED_ERRORS, error_msg)) {
			return undefined;
		}
		return error_msg;
	}
	return undefined;
};
