import React from "react";

export const Search = ({ unit = "em", ...rest } = {}) => (
	<svg viewBox="0 0 24 24" {...rest}>
		<defs>
			<path
				d="M19.9425,17.806875 L16.1625,14.40675 C18.1582922,11.5022095 17.612067,7.55538022 14.9024462,5.3020499 C12.1928254,3.04871958 8.2125634,3.23130589 5.72062214,5.72324714 C3.22868089,8.2151884 3.04609458,12.1954504 5.2994249,14.9050712 C7.55275522,17.614692 11.4995845,18.1609172 14.404125,16.165125 L17.80425,19.945875 C17.8397007,19.9853698 17.8772552,20.0229243 17.91675,20.058375 C18.5406739,20.5982503 19.4819299,20.5394281 20.0337847,19.9260745 C20.5856395,19.3127209 20.5450534,18.3705024 19.9425,17.806875 L19.9425,17.806875 Z M5.259,10.557 C5.259,7.63306646 7.62931646,5.26275 10.55325,5.26275 C13.4771835,5.26275 15.8475,7.63306646 15.8475,10.557 C15.8475,13.4809335 13.4771835,15.85125 10.55325,15.85125 C7.63068717,15.8479435 5.26230654,13.4795628 5.259,10.557 Z"
				id="path-1"
			/>
		</defs>
		<g id="UI/Icon/Search/Red" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<mask id="mask-2" fill="white">
				<use xlinkHref="#path-1" />
			</mask>
			<use id="Shape" fill="#DF004D" fillRule="nonzero" xlinkHref="#path-1" />
		</g>
	</svg>
);

export default Search;
