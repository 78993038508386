import React from "react";

export const Flights = ({ unit = "em", ...rest } = {}) => (
	<svg
		viewBox="0 0 120 120"
		version="1.1"
		xmlns="http://www.w3.org/2000/svg"
		xmlnsXlink="http://www.w3.org/1999/xlink"
		fill="#DF004D"
		{...rest}
	>
		<title>Flights</title>
		<g id="UI/Prize-Icons/Flights" stroke="none" strokeWidth="1" fillRule="evenodd">
			<path
				d="M30.8142675,43.4196109 L36.5919427,39.8429548 L69.744793,42.1815376 L56.1259873,50.8480504 L30.8142675,43.4196109 Z M5.50254777,71.0699134 L22.1477548,76.1597701 C23.1107006,76.7100249 24.6239013,76.5724612 25.5868471,75.8846427 L28.6132484,73.8211873 C54.2000955,56.3505981 80.6123248,39.8429548 107.299682,24.1606937 L107.299682,24.1606937 C111.013901,22.0972383 115.966194,22.7850567 118.442341,26.2241491 C121.193615,30.0759325 120.230669,35.3033529 116.103758,37.9170631 L87.2153822,58.1389262 L69.3321019,100.508544 L60.9407166,105.735964 C60.9407166,105.735964 67.1310828,68.8688943 66.9935191,69.006458 L22.1477548,87.9902478 C21.0472452,88.4029389 19.9467357,88.4029389 18.9837898,87.7151204 L0,74.5090058 L5.50254777,71.0699134 Z"
				id="Shape"
				fillRule="nonzero"
			/>
		</g>
	</svg>
);

export default Flights;
