import * as React from "react";
import styled from "styled-components";
import colors from "../../../assets/colors";

export const TooltipBubble = styled.div`
	width: 0;
	opacity: 0;
	visibility: hidden;

	position: absolute;
	right: calc(100% + 8px);
	top: 50%;
	transform: translateY(-100%);

	max-width: 300px;
	padding: 10px 15px;
	border-radius: 5px;

	font-family: Radikal;
	font-size: 14px;
	font-weight: 400;
	text-transform: none;

	color: white;
	background-color: ${colors.primary.primary};

	transition: width 0s linear 0.2s, transform 0.2s ease-in, opacity 0.2s ease-in,
		visibility 0s linear 0.2s;

	:after {
		content: "";

		position: absolute;
		top: 50%;
		left: 100%;
		transform: translateY(-50%);

		height: 0;
		width: 0;

		margin: 0;
		padding: 0;

		border-style: solid;
		border-width: 6px;
		border-color: transparent;
		border-left-color: ${colors.primary.primary};
	}
`;

export const TooltipLabel = styled.div`
	position: relative;

	font-family: Radikal;
	font-size: 14px;
	font-weight: 600;
	color: ${colors.text.disabled};
	text-transform: uppercase;

	transition: color 0.2s linear;

	:hover {
		color: ${colors.primary.primary};

		${TooltipBubble} {
			width: calc(90vw - 65px);
			opacity: 1;
			visibility: visible;
			transform: translateY(-50%);
			transition: width 0s linear, visibility 0s linear, transform 0.2s ease-in,
				opacity 0.2s ease-in;
		}
	}
`;

export const TooltipWrapper = styled.div`
	position: absolute;

	top: 50px;
	right: 40px;
	z-index: 10;

	@media screen and (max-width: 768px) {
		top: 40px;
		right: 20px;
	}
`;

export const HelpTooltip = ({ text }) => {
	return (
		<TooltipLabel>
			Help
			<TooltipBubble>{text}</TooltipBubble>
		</TooltipLabel>
	);
};

export default HelpTooltip;
