import { combineReducers } from "redux";
import _ from "lodash";
import {
	FETCH_POSITIONS_SUCCESS,
	FETCH_POSITIONS_BEGIN,
	FETCH_POSITIONS_FAIL
} from "../constants/actionTypes/positions";

const byId = (state = {}, action) => {
	switch (action.type) {
		case FETCH_POSITIONS_SUCCESS:
			if (!action.positions) {
				return state;
			}
			return _.keyBy(action.positions, "id");
		default:
			return state;
	}
};

const allIdsOrdered = (state = [], action) => {
	switch (action.type) {
		case FETCH_POSITIONS_SUCCESS:
			if (!action.positions) {
				return state;
			}
			return action.positions.map(position => position.id);
		default:
			return state;
	}
};

const isFetching = (state = false, action) => {
	switch (action.type) {
		case FETCH_POSITIONS_BEGIN:
			return true;
		case FETCH_POSITIONS_SUCCESS:
			return false;
		case FETCH_POSITIONS_FAIL:
			return false;
		default:
			return state;
	}
};

const checksum = (state = null, action) => {
	switch (action.type) {
		case FETCH_POSITIONS_SUCCESS:
			if (!action.checksum) {
				return state;
			}
			return action.checksum;
		default:
			return state;
	}
};

const positions = combineReducers({
	byId,
	allIdsOrdered,
	checksum,
	isFetching
});

export default positions;

// Positions state selectors
export const getAllPositionsAsOrderedArray = state =>
	state.positions.allIdsOrdered.map(id => state.positions.byId[id]);

export const getAllPositions = state => state.positions.byId;

export const isFetchingPositions = state => state.positions.isFetching;

export const isEmptyPositions = state => state.positions.allIdsOrdered.length === 0;

export const getPositionsChecksum = state => state.positions.checksum;
