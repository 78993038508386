import {
	identity, negate, partial, chain, has, overEvery, nthArg, flow, toInteger
} from 'lodash';
export const filters = {
	by_name: (look_for, {firstName, lastName}) => (
		`${firstName} ${lastName}`.toLowerCase().includes(look_for.toLowerCase())
	),
	by_position: (positionId, {positions}) => positions.includes(toInteger(positionId)),
	by_squad: (look_for_squad_id, {squadId}) => squadId === toInteger(look_for_squad_id),
};

const mapFilterKeyToFilter = ([key, value]) => (
	has(filters, key) ? partial(filters[key], value) : identity
);

export const createPlayersFilterList = (filters_criteria) => {
	return chain(filters_criteria).omitBy(negate(identity)).toPairs().map(mapFilterKeyToFilter).value();
};

export const createPlayersFilter = (filters_criteria) => flow([
	nthArg(0),
	overEvery(createPlayersFilterList(filters_criteria))
]);