import styled from "styled-components";
import * as React from "react";
import PropTypes from "prop-types";
import colors from "../../assets/colors";

export const SplitPanelWrapper = styled.div`
	background-color: white;
	color: ${colors.text.black};
	margin: 0 0 40px;

	display: flex;
	flex-direction: row;

	@media screen and (max-width: 768px) {
		flex-direction: column;
	}
`;

export const SplitWrapper = styled.div`
	flex: 1 1 0px;

	&:first-child {
		box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.5);
	}
`;

export const SplitPanel = ({ left, right }) => {
	return (
		<SplitPanelWrapper>
			<SplitWrapper>{left}</SplitWrapper>
			<SplitWrapper>{right}</SplitWrapper>
		</SplitPanelWrapper>
	);
};

SplitPanel.propTypes = {
	left: PropTypes.any,
	right: PropTypes.any
};
