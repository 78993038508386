import styled from "styled-components";
import colors from "../../../../assets/colors";

export const Label = styled.label`
	color: ${colors.text.disabled};
	font-size: 14px;
	font-family: Radikal;
	font-weight: 600;
	text-transform: uppercase;
	> span.error {
		color: ${colors.form.error};
	}
`;

export default Label;
