import styled from "styled-components";

export const NetPointsWrapper = styled.div`
	flex: 1 0 0px;

	display: flex;
	flex-direction: column;
	align-items: flex-start;
`;

export const ScoreLabel = styled.div`
	font-family: Radikal;
	font-size: 8px;
	text-transform: uppercase;
	margin-bottom: 4px;
	margin-top: -4px;

	@media screen and (max-width: 1024px) {
		font-size: 7px;
	}

	@media screen and (max-width: 320px) {
		margin-top: -2px;
		font-size: 6px;
	}
`;

export default NetPointsWrapper;
