import React from "react";

export const ArrowLeft = ({ unit = "em", ...rest } = {}) => (
	<svg viewBox="0 0 24 24" {...rest}>
		<g id="UI/Icon/Arrow-Left/Black" stroke="none" strokeWidth="1" fillRule="evenodd">
			<polygon
				id="Shape"
				points="15.4 16.8999756 14 18.2999756 8 12.2999756 14 6.29997559 15.4 7.69997559 10.8 12.2999756"
			/>
		</g>
	</svg>
);

export default ArrowLeft;
