import { combineReducers } from "redux";
import _ from "lodash";
import {
	FETCH_ROUNDS_SUCCESS,
	FETCH_ROUNDS_BEGIN,
	FETCH_ROUNDS_FAIL
} from "../constants/actionTypes/rounds";
const OFF_SEASON = JSON.parse(process.env.REACT_APP_OFF_SEASON || 'false');

const byId = (state = {}, action) => {
	switch (action.type) {
		case FETCH_ROUNDS_SUCCESS:
			if (!action.rounds) {
				return state;
			}
			let rounds = [...action.rounds];

			if (OFF_SEASON) {
				rounds =  [...rounds, {id: 'F', status: 'complete'}];
			}

			return _.keyBy(rounds, "id");
		default:
			return state;
	}
};

const allIdsOrdered = (state = [], action) => {
	switch (action.type) {
		case FETCH_ROUNDS_SUCCESS:
			if (!action.rounds) {
				return state;
			}
			let rounds = [...action.rounds];

			if (OFF_SEASON) {
				rounds =  [...rounds, {id: 'F', status: 'complete'}];
			}
			return rounds.map(round => round.id);
		default:
			return state;
	}
};

const isFetching = (state = false, action) => {
	switch (action.type) {
		case FETCH_ROUNDS_BEGIN:
			return true;
		case FETCH_ROUNDS_SUCCESS:
			return false;
		case FETCH_ROUNDS_FAIL:
			return false;
		default:
			return state;
	}
};

const checksum = (state = null, action) => {
	switch (action.type) {
		case FETCH_ROUNDS_SUCCESS:
			if (!action.checksum) {
				return state;
			}
			return action.checksum;
		default:
			return state;
	}
};

const rounds = combineReducers({
	byId,
	allIdsOrdered,
	checksum,
	isFetching
});

export default rounds;

// Rounds state selectors
export const getAllRoundsAsOrderedArray = state =>
	state.rounds.allIdsOrdered.map(id => state.rounds.byId[id]);

export const getAllRounds = state => state.rounds.byId;

export const isFetchingRounds = state => state.rounds.isFetching;

export const isEmptyRounds = state => state.rounds.allIdsOrdered.length === 0;

export const getRoundsChecksum = state => state.rounds.checksum;
