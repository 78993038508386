import React from "react";

export const ArrowRight = ({ unit = "em", ...rest } = {}) => (
	<svg viewBox="0 0 24 24" {...rest}>
		<g id="UI/Icon/Arrow-Right/Black" stroke="none" strokeWidth="1" fillRule="evenodd">
			<polygon
				id="Shape"
				points="8 16.8999756 12.6 12.2999756 8 7.69997559 9.4 6.29997559 15.4 12.2999756 9.4 18.2999756"
			/>
		</g>
	</svg>
);

export default ArrowRight;
