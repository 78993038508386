import React from "react";

export const FB = ({ unit = "em", is_active, ...rest } = {}) => (
	<svg width="60px" height="60px" viewBox="0 0 60 60" {...rest}>

		<g id="Desktop---[Competition]-Onboarding" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<g id="Global---Landing-(Form-Submit)-[D]" transform="translate(-647.000000, -1250.000000)">
				<g id="Enrty" transform="translate(115.000000, 912.000000)">
					<g id="Form" transform="translate(0.000000, 0.000000)">
						<g id="Social" transform="translate(452.000000, 338.000000)">
							<g id="Facebook" transform="translate(80.000000, 0.000000)">
								<g id="UI/Icon/Facebook/Black" transform="translate(0.000000, -0.000000)">
									<path d="M40,12.7524038 L40,18.3052885 L36.3657407,18.3052885 C35.0385736,18.3052885 34.1435208,18.5576898 33.6805556,19.0625 C33.2175903,19.5673102 32.9861111,20.3245142 32.9861111,21.3341346 L32.9861111,25.3094952 L39.7685185,25.3094952 L38.8657407,31.5354567 L32.9861111,31.5354567 L32.9861111,47.5 L25.9027778,47.5 L25.9027778,31.5354567 L20,31.5354567 L20,25.3094952 L25.9027778,25.3094952 L25.9027778,20.7241587 C25.9027778,18.1159725 26.7052389,16.0932564 28.3101852,14.6559495 C29.9151315,13.2186427 32.0524558,12.5 34.7222222,12.5 C36.9907521,12.5 38.7499937,12.5841338 40,12.7524038 Z" id="Facebook-Icon" fill="#DF004D" fillRule="evenodd" />
									<rect id="Rectangle" stroke="#DF004D" strokeWidth="1" x="0.5" y="0.5" width="59" height="59"/>
								</g>
							</g>
						</g>
					</g>
				</g>
			</g>
		</g>
	</svg>
);

export default FB;