import React from "react";

export const ArrowDown = ({ unit = "em", ...rest } = {}) => (
	<svg viewBox="0 0 24 24" {...rest}>
		<g id="UI/Icon/Arrow-Down/Red" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<polygon
				id="Shape"
				fill="#DF004D"
				points="16.6 8.59997559 12 13.1999756 7.4 8.59997559 6 9.99997559 12 15.9999756 18 9.99997559"
			/>
		</g>
	</svg>
);

export default ArrowDown;
