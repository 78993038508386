import React from "react";
import PageLayout from "./containers/PageLayout";
import Header from "./components/molecules/Header";
import Footer from "./components/molecules/Footer";
import MainPage from "./components/organisms/MainPage";
import GlobalWrapper from "./containers/PageLayout/globalWrapper";

function App() {
	return (
		<GlobalWrapper>
			<PageLayout>
				<Header />
				<MainPage />
				<Footer />
			</PageLayout>
		</GlobalWrapper>
	);
}

export default App;
