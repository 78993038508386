import styled from "styled-components";
import DesktopBG from "../../assets/images/DesktopBG.png";

export const GlobalWrapper = styled.div`
	background-image: url(${DesktopBG});
	background-size: 1530px;
	background-position: top center;
	background-repeat: no-repeat;
	color: white;

	@media screen and (max-width: 768px) {
		/* background-size: 1240px; */
	}
`;

export default GlobalWrapper;
