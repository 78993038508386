import { combineReducers } from "redux";
import _ from "lodash";
import {
	FETCH_PLAYERS_SUCCESS,
	FETCH_PLAYERS_BEGIN,
	FETCH_PLAYERS_FAIL
} from "../constants/actionTypes/players";

const byId = (state = {}, action) => {
	switch (action.type) {
		case FETCH_PLAYERS_SUCCESS:
			if (!action.players) {
				return state;
			}
			return _.keyBy(action.players, "id");
		default:
			return state;
	}
};

const allIdsOrdered = (state = [], action) => {
	switch (action.type) {
		case FETCH_PLAYERS_SUCCESS:
			if (!action.players) {
				return state;
			}
			return action.players.map(player => player.id);
		default:
			return state;
	}
};

const isFetching = (state = false, action) => {
	switch (action.type) {
		case FETCH_PLAYERS_BEGIN:
			return true;
		case FETCH_PLAYERS_SUCCESS:
			return false;
		case FETCH_PLAYERS_FAIL:
			return false;
		default:
			return state;
	}
};

const checksum = (state = null, action) => {
	switch (action.type) {
		case FETCH_PLAYERS_SUCCESS:
			if (!action.checksum) {
				return state;
			}
			return action.checksum;
		default:
			return state;
	}
};

const players = combineReducers({
	byId,
	allIdsOrdered,
	checksum,
	isFetching
});

export default players;

// Players state selectors
export const getAllPlayersAsOrderedArray = state => state.players.allIdsOrdered.map(id => state.players.byId[id]);

export const getAllPlayers = state => state.players.byId;

export const isFetchingPlayers = state => state.players.isFetching;

export const isEmptyPlayers = state => state.players.allIdsOrdered.length === 0;

export const getPlayersChecksum = state => state.players.checksum;

export const getPlayerSquadId = (state, id) => {
	return state.players.byId[id].squadId;
};
