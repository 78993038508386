import axios from "axios";
import _ from "lodash";
import camelcaseKeys from "camelcase-keys";

const fetchQuestions = async function fetchQuestions() {
	// TODO:: Maybe create an API Client to deal with building the urls and possible error logging and management. The idea would to have an API Client that would be passed a config object that includes base urls and also pass in an error logging function
	const baseUrl = process.env.REACT_APP_JSON_URL;
	const cacheBust = `_=${Date.now()}`;
	const questionsPayload = await axios.get(`${baseUrl}/polling.json?${cacheBust}`);
	/*
	const questionsPayloadFormatted = {
		...questionsPayload,
		data: questionsPayload.data.map(item => ({ ...item, locked: 0, status: "scheduled" }))
	};
	return camelcaseKeys(questionsPayloadFormatted, { deep: true });
	console.log(questionsPayload.data.map(question => [question.locked, question.status]));
	*/
	return camelcaseKeys(questionsPayload, { deep: true });
};

const submitAnswer = async function fetchQuestions(data) {
	const baseUrl = process.env.REACT_APP_API_URL;
	const cacheBust = `_=${Date.now()}`;
	const form_data = toFormData(data);
	const result = await axios.post(`${baseUrl}/polling/save?${cacheBust}`, form_data);
	return camelcaseKeys(result, { deep: true });
};

const submitUserData = async function fetchQuestions(data) {
	const baseUrl = process.env.REACT_APP_API_URL;
	const cacheBust = `_=${Date.now()}`;
	const form_data = toFormData(data);
	const result = await axios.post(`${baseUrl}/polling/sweepstakes?${cacheBust}`, form_data);
	return camelcaseKeys(result, { deep: true });
};

const toFormData = json => {
	const form_data = new FormData();

	_.forEach(json, (value, key) => {
		if (_.endsWith(key, "opt_in")) {
			form_data.set(key, value ? 1 : 0);
		} else {
			form_data.set(key, value);
		}
	});

	return form_data;
};

export default {
	fetchQuestions,
	submitAnswer,
	submitUserData
};
