import { identity } from "lodash";
const FB_SHARE_URL = process.env.REACT_APP_FB_SHARE_URL || "";
const protocol = window.location.protocol;

/**
 * in order to reduce the size of the string for sharing
 */
const last_four_number = -4;
const getTimestamp = () =>
	Date.now()
		.toString()
		.substr(last_four_number);

const getEncodedJson = object => btoa(JSON.stringify(object));
const getLinkForShare = object => `${protocol}${FB_SHARE_URL}/${getEncodedJson(object)}`;

export const share = data => {
	if (data.sc === "tw") {
		shareTwitter(data);
	}

	if (data.sc === "fb") {
		shareFB(data);
	}
};

export const shareFB = data => {
	const { sc, player_id, round_id } = data;
	const FB_API = window.FB;
	const object = {
		sc,
		t: getTimestamp(),
		player_id,
		round_id
	};

	FB_API.ui(
		{
			method: "share",
			display: "popup",
			href: getLinkForShare(object)
		},
		identity
	);
};

export const shareTwitter = data => {
	const { sc, message = "", player_id, round_id } = data;
	const object = {
		sc,
		t: getTimestamp(),
		player_id,
		round_id
	};
	const href = getLinkForShare(object);

	window.open(
		"https://twitter.com/share?url=" +
			encodeURIComponent(href) +
			"&text=" +
			encodeURIComponent(message),
		"twitter-share-dialog",
		"width=750,height=668"
	);
};
