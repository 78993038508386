import checksums from "./checksums";
import rounds from "./rounds";
import squads from "./squads";
import questions from "./questions";
import players from "./players";
import positions from "./positions";

export default {
	checksums,
	players,
	positions,
	rounds,
	squads,
	questions
};
