import styled from "styled-components";

export { Input } from "./Input";
export { Select } from "./Select";
export { Label } from "./Label";
export { CheckBox } from "./CheckBox";

const Form = styled.form`
	> div {
		margin-bottom: 20px;

		:last-child {
			margin-bottom: 0;
		}
	}
`;

export default Form;
