import * as React from "react";
import styled from "styled-components";
import TeamOfTheWeek from "../../Icons/TeamOfTheWeek";
import NetPointsLandscape from "../../../assets/images/net-points/white/landscape-2020.svg";

const OFF_SEASON = JSON.parse(process.env.REACT_APP_OFF_SEASON || "false");

const NetPointsLogoWrapper = styled.div`
	margin: 24px 0 0;

	display: flex;
	justify-content: center;

	> img {
		height: 75px;
	}

	@media screen and (max-width: 768px) {
		margin: 25px 0 0;

		> img {
			height: 50px;
		}
	}
`;

const HeaderText = styled.div`
	max-width: 325px;
	color: white;
	font-family: Radikal;
	font-weight: 600;
	font-size: 16px;
	text-align: center;

	margin-top: 10px;

	> span {
		font-weight: 700;
	}

	@media screen and (max-width: 768px) {
		font-size: 14px;
		max-width: 260px;
	}
`;

const CentreWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;

	color: white;

	> svg {
		margin-top: 20px;
		height: 30px;

		fill: white;

		@media screen and (max-width: 768px) {
			height: 20px;
			margin-top: 10px;
		}
	}
`;

const NetPointsLogoImg = styled.img`
	max-width: 480px;
	@media screen and (max-width: 768px) {
		max-width: 300px;
	}
	@media screen and (max-width: 600px) {
		max-width: 150px;
	}
`;

export class CentreLogos extends React.Component {
	render() {
		return (
			<CentreWrapper>
				<NetPointsLogoWrapper>
					<NetPointsLogoImg src={NetPointsLandscape} alt="Net Points Logo" />
				</NetPointsLogoWrapper>
				<TeamOfTheWeek />
				<HeaderText>
					{OFF_SEASON ? (
						"Season Complete!"
					) : (
						<React.Fragment>
							Select your <span>Game Changer</span> of the round for your chance to
							win!
						</React.Fragment>
					)}
				</HeaderText>
			</CentreWrapper>
		);
	}
}

export default CentreLogos;
