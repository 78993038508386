import * as React from "react";
// import _ from "lodash";
import styled from "styled-components";
import PropTypes from "prop-types";
// import numeral from "numeral";

const ScoreWrapper = styled.div`
	font-size: 20px;
	font-weight: 700;

	@media screen and (max-width: 1024px) {
		font-size: 12px;
	}
`;

// const formatNumber = score => numeral(score).format("0[.]0");

export const NetPointsScore = ({ score }) => {
	// const scaled_score = _.isNumber(score) ? score / 10 : score;

	// return <ScoreWrapper>{formatNumber(scaled_score)}</ScoreWrapper>;
	return <ScoreWrapper>{score}</ScoreWrapper>;

};

NetPointsScore.propTypes = {
	score: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default NetPointsScore;
