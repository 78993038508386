import axios from "axios";

const fetchChecksums = async function fetchChecksums() {
	// TODO:: Maybe create an API Client to deal with building the urls and possible error logging and management. The idea would to have an API Client that would be passed a config object that includes base urls and also pass in an error logging function
	const baseUrl = process.env.REACT_APP_JSON_URL;
	const cacheBust = `_=${Date.now()}`;
	const checksumsPayload = await axios.get(`${baseUrl}/checksums.json?${cacheBust}`);
	return checksumsPayload;
};

export default {
	fetchChecksums
};
