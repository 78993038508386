import styled from "styled-components";

export const AvatarSection = styled.div`
	display: flex;
	align-items: flex-end;

	position: relative;

	> img {
		width: 100%;
		position: relative;
		z-index: 2;
	}
`;

export default AvatarSection;
