import {
	FETCH_ROUNDS_BEGIN,
	FETCH_ROUNDS_SUCCESS,
	FETCH_ROUNDS_FAIL
} from "../constants/actionTypes/rounds";
import { getRoundsChecksum, isFetchingRounds } from "../reducers/rounds";

function fetchRoundsBegin() {
	return {
		type: FETCH_ROUNDS_BEGIN
	};
}

function fetchRoundsSuccess(rounds, checksum) {
	return {
		type: FETCH_ROUNDS_SUCCESS,
		rounds,
		checksum
	};
}

function fetchRoundsFail(error) {
	return {
		type: FETCH_ROUNDS_FAIL,
		error
	};
}

function fetchRounds() {
	return async (dispatch, getState, { api }) => {
		if (isFetchingRounds(getState())) {
			return false;
		}

		dispatch(fetchRoundsBegin());

		try {
			const checksums = await api.checksums.fetchChecksums();
			const roundsChecksum = checksums.data.rounds;
			const isDataStale = roundsChecksum !== getRoundsChecksum(getState());

			if (!isDataStale) {
				return dispatch(fetchRoundsSuccess());
			}

			const rounds = await api.rounds.fetchRounds();
			return dispatch(fetchRoundsSuccess(rounds.data, roundsChecksum));
		} catch (error) {
			console.error(error);
			// if (sentryClient) {
			// 	sentryClient.captureException(error, {
			// 		extra: {
			// 			failedAction: "fetchRounds"
			// 		}
			// 	});
			// }
			return dispatch(fetchRoundsFail(error));
		}
	};
}

export default {
	fetchRounds
};
