import * as React from "react";
import { connect } from "react-redux";
import _ from "lodash";
import styled from "styled-components";
import Chance from "chance";

import PanelsColumn from "../../../containers/ContentPanel/panelsColumn";
import { SplitPanel } from "../../../containers/ContentPanel/SplitPanel";
import ContentPanel from "../../../containers/ContentPanel";

import TeamOfTheWeek from "../../molecules/TeamOfTheWeek";
import RoundSelector from "../../molecules/RoundSelector";
import VotingForm from "../../molecules/VotingForm";

import withRoundsSelector from "../../utils/withRoundsSelector";
import PrizeSection from "../../molecules/PrizeSection";
import squads from "../../../actions/squads";
import players from "../../../actions/players";
import rounds from "../../../actions/rounds";
import questions from "../../../actions/questions";
import positions from "../../../actions/positions";
import { getRounds, getCurrentQuestionId, getLastCompleteRoundId } from "../../../selectors/rounds";
import voting from "../../../actions/voting";
import Article from "../../molecules/HighlightsSection/Article";
import WinnerSection from "../../molecules/WinnerSection";
import ComingSoon from "../../molecules/ComingSoon";
import ComingSoonOffSeason from "../../molecules/ComingSoonOffSeason";
import { SEASON_START_ROUND_ID } from "../../../constants/vars";
import PostVote from "../../molecules/PostVote";
import colors from "../../../assets/colors";
import { getFormErrorText } from "../../../utils/apiErrors";
import { Leaderboard } from "../../../containers/Leaderboard";

const SecondaryPanel = styled(ContentPanel)`
	background-color: transparent;
	margin: 0;
`;

const SubmissionError = styled(SecondaryPanel)`
	color: ${colors.primary.primary};
	font-family: Radikal;
	font-size: 20px;
	padding-bottom: 0px;
`;

class MainPageComponent extends React.Component {
	constructor(props) {
		super(props);

		_.bindAll(this, ["handleVotingSubmit"]);
	}
	componentDidMount() {
		this.props.fetchSquads();
		this.props.fetchPlayers();
		this.props.fetchRounds();
		this.props.fetchQuestions();
		this.props.fetchPositions();
	}
	componentDidUpdate(prev_props) {
		const { rounds_loaded, last_complete_round_id = 0, setSelectedRoundId } = this.props;
		if (rounds_loaded && !prev_props.rounds_loaded) {
			const start_round = _.isString(last_complete_round_id)
				? last_complete_round_id
				: Math.max(last_complete_round_id, SEASON_START_ROUND_ID);

			setSelectedRoundId(start_round);
		}
	}
	get season_started() {
		const { selected_round_id } = this.props;
		return selected_round_id <= SEASON_START_ROUND_ID;
	}
	getUserKey(email) {
		return new Chance(email).string({
			length: 8,
			pool: "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789"
		});
	}
	makeSubmissionData(values) {
		const { question_id, selected_option } = this.props;
		const user_key = this.getUserKey(values.email);

		return {
			answer: {
				user_key,
				question_id,
				option_id: selected_option
			},
			user_data: {
				user_key,
				question_id,
				email_opt_in: Boolean(values.email_opt_in),
				sponsor_opt_in: Boolean(values.sponsor_opt_in),
				...values
			}
		};
	}
	handleVotingSubmit(values) {
		const { submitVote } = this.props;
		const submissionData = this.makeSubmissionData(values);
		// console.log("Submitting Voting Form with ", submissionData);
		submitVote(submissionData);
	}
	renderMainContent() {
		const { selected_round, vote_submitted } = this.props;

		if (!_.get(selected_round, "question.forPublish", true)) {
			return this.renderPreVoting();
		}
		if (_.get(selected_round, "question.locked", false)) {
			if (_.get(selected_round, "question.hasAnswer", false)) {
				return this.renderWinner();
			}
			return this.renderVotingClosed();
		}
		if (vote_submitted) {
			return this.renderVoteSubmitted();
		}
		return this.renderVotingForm();
	}
	renderSubmissionError() {
		const { submission_error } = this.props;
		const error_msg = getFormErrorText(submission_error);
		if (!error_msg) {
			return null;
		}
		return (
			<React.Fragment>
				<SubmissionError>{error_msg}</SubmissionError>
			</React.Fragment>
		);
	}
	renderVoteSubmitted() {
		return (
			<SecondaryPanel>
				<PostVote />
			</SecondaryPanel>
		);
	}
	/**
	 * Before voting opens, in the main content, show the voting form with everything disabled
	 */
	renderPreVoting() {
		return this.renderVotingForm(true);
	}
	/**
	 * Voting is closed, but there's no answer/winner yet
	 */
	renderVotingClosed() {
		return this.renderVotingForm(true);
	}
	renderWinner() {
		// TODO get the winner from the API
		const { selected_round_id } = this.props;

		return (
			<SecondaryPanel>
				<WinnerSection round_id={selected_round_id} />
			</SecondaryPanel>
		);
	}
	renderVotingForm(disabled = false) {
		const { selected_round } = this.props;
		if (_.get(selected_round, "question.locked", true)) {
			// TODO render "come backsoon to see who won" message?
			return null;
		}
		return (
			<React.Fragment>
				{this.renderSubmissionError()}
				<VotingForm
					onSubmit={this.handleVotingSubmit}
					disabled={disabled}
					season_started={this.season_started}
				/>
			</React.Fragment>
		);
	}
	renderSecondaryPanel() {
		return (
			<SecondaryPanel>
				<Article />
				<PrizeSection />
			</SecondaryPanel>
		);
	}
	renderTeamComingSoon() {
		const { selected_round } = this.props;
		if (!selected_round) {
			return null;
		}

		return (
			<SecondaryPanel>
				<ComingSoon
					before_season={this.season_started}
					start_round={SEASON_START_ROUND_ID}
				/>
			</SecondaryPanel>
		);
	}
	renderTeam() {
		const { selected_round, selected_round_id } = this.props;

		if (_.isString(selected_round_id)) {
			return (
				<SecondaryPanel>
					<ComingSoonOffSeason />
				</SecondaryPanel>
			);
		}

		if (!_.get(selected_round, "question.forPublish", false)) {
			return this.renderTeamComingSoon();
		}

		return <TeamOfTheWeek round={selected_round} />;
	}
	render() {
		const {
			rounds_with_questions,
			selected_round_id,
			setSelectedRoundId,
			selected_round
		} = this.props;
		return (
			<PanelsColumn>
				{this.renderTeam()}
				<RoundSelector
					rounds={rounds_with_questions}
					selected={selected_round_id}
					handleClick={setSelectedRoundId}
				/>
				{_.isString(selected_round_id) ? null : (
					<SplitPanel
						left={this.renderMainContent()}
						right={this.renderSecondaryPanel()}
					/>
				)}

				<Leaderboard round={selected_round} />
			</PanelsColumn>
		);
	}
}

const mapStateToProps = (state, props) => {
	const rounds_with_questions = getRounds(state, props);
	const selected_round = _.find(rounds_with_questions, { id: props.selected_round_id });
	return {
		rounds_loaded: !state.questions.isFetching && !state.rounds.isFetching,
		last_complete_round_id: getLastCompleteRoundId(rounds_with_questions),
		rounds_with_questions,
		selected_round,
		question_id: getCurrentQuestionId(state, props),
		selected_option: _.get(state.voting.player, "option_id", undefined),
		vote_submitted: state.voting.submitted,
		submission_error: state.voting.error
	};
};
const mapDispatchToProps = {
	fetchSquads: squads.fetchSquads,
	fetchPlayers: players.fetchPlayers,
	fetchRounds: rounds.fetchRounds,
	fetchQuestions: questions.fetchQuestions,
	fetchPositions: positions.fetchPositions,
	submitVote: voting.submitVote
};

export const MainPage = withRoundsSelector(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(MainPageComponent)
);

export default MainPage;
