import * as React from "react";
import styled, { css } from "styled-components";
import PropTypes from "prop-types";
import colors from "../../../../assets/colors";
import Tick from "../../../Icons/Tick";
import { lighten } from "polished";

const CheckBoxText = styled.label`
	font-family: Radikal;
	font-weight: 400;
	font-size: 12px;
	color: ${colors.text.black};
	margin-top: 8px;

	flex: 1 1 0px;
`;

const CustomCheckBox = styled.label`
	width: 30px;
	height: 30px;
	border-radius: 50%;

	background-color: white;
	border: 1px solid ${colors.primary.primary};

	transition: background-color 0.2s linear;

	margin-right: 20px;

	> svg {
		fill: white;
	}
`;

const CheckBoxInput = styled.input`
	visibility: hidden;
	width: 0;
	margin: 0;

	&:checked + ${CustomCheckBox} {
		background: ${colors.primary.primary};
	}
`;

const CheckBoxWrapper = styled.div`
	box-sizing: border-box;
	padding: 0;
	width: 100%;

	display: flex;
	align-items: flex-start;
	justify-content: space-between;

	> svg {
		height: 24px;
		width: 24px;
	}

	${({ disabled }) =>
		!disabled &&
		css`
			:hover ${CustomCheckBox} {
				background-color: ${lighten(0.1, colors.primary.primary)}!important;
			}
		`}
`;

export class CheckBox extends React.Component {
	static defaultProps = {
		is_search: false
	};
	render() {
		const { text, input, is_search, defaultChecked, disabled, ...rest } = this.props;
		const name = input.name;
		const checked = input.value;

		return (
			<CheckBoxWrapper disabled={disabled}>
				<CheckBoxInput
					{...rest}
					{...input}
					id={name}
					type="checkbox"
					defaultChecked={defaultChecked}
					checked={checked}
					disabled={disabled}
					readOnly
				/>
				<CustomCheckBox htmlFor={name}>
					<Tick />
				</CustomCheckBox>
				<CheckBoxText htmlFor={name}>{text}</CheckBoxText>
			</CheckBoxWrapper>
		);
	}
}

CheckBox.propTypes = {
	placeholder: PropTypes.string,
	input: PropTypes.object.isRequired,
	meta: PropTypes.object.isRequired,
	text: PropTypes.any,
	defaultChecked: PropTypes.bool
};

export default CheckBox;
