import styled from "styled-components";
import * as React from "react";
import BackTriangleIcon from "../../Icons/BackTriangle";

const TriangleWrapper = styled.div`
	position: absolute;
	bottom: 0;
	left: 0;

	width: 100%;

	opacity: 0.7;

	> svg {
		width: 100%;
		height: auto;
	}

	display: flex;
	align-items: flex-end;
`;

export const BackTriangle = () => {
	return (
		<TriangleWrapper>
			<BackTriangleIcon />
		</TriangleWrapper>
	);
};

export default BackTriangle;
