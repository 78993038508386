import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import FormUnstyled from "../../atoms/Form";
import SelectedPlayer from "../../atoms/SelectedPlayer";
import colors from "../../../assets/colors";
import Title from "../../atoms/Title";
import FB from "../../Icons/FB";
import Twitter from "../../Icons/Twitter";
import { share } from "../../../utils";
import { getRounds } from "../../../selectors/rounds";
import _ from "lodash";
import withRoundsSelector from "../../utils/withRoundsSelector";
const SubmitMessage = styled.div`
	font-family: Radikal;
	font-size: 20px;
	color: ${colors.text.black};
`;
const SubmittedWrapper = styled(FormUnstyled)`
	position: relative;
	padding: 50px 40px;

	@media screen and (max-width: 768px) {
		padding: 40px 20px;
	}
`;

const TitleStyled = styled(Title)`
	display: flex;
	align-items: center;
	font-family: Radikal;
	font-weight: 700;
	text-transform: uppercase;
	font-size: 30px;
	svg {
		margin-left: 20px;
		cursor: pointer;
	}
`;

class PostVoteComponent extends React.Component {
	static defaultProps = {
		disabled: false,
		season_started: true
	};

	handleShare(type_share) {
		const { selected_player, selected_round } = this.props;

		share({
			sc: type_share,
			player_id: selected_player ? selected_player.id : 0,
			round_id: selected_round ? selected_round.id : 0,
			message:
				"I just voted for my Nissan Net Points Game Changer of the Round! Who was your Game Changer?"
		});
	}

	render() {
		const { selected_player } = this.props;
		return (
			<SubmittedWrapper as="div">
				<SelectedPlayer player={selected_player} />
				<SubmitMessage>
					Thank-you for voting for your Nissan Net Points Game Changer of the Round! You
					are now in the running for some fantastic prizes.
				</SubmitMessage>

				<TitleStyled>
					SHARE YOUR PICK
					<FB onClick={() => this.handleShare("fb")} />
					<Twitter onClick={() => this.handleShare("tw")} />
				</TitleStyled>
			</SubmittedWrapper>
		);
	}
}

const mapStateToProps = (state, props) => {
	const player = state.voting.player || state.players.byId[991901];
	const rounds_with_questions = getRounds(state, props);
	const selected_round = _.find(rounds_with_questions, { id: props.selected_round_id });

	return {
		selected_player: player ? player : undefined,
		selected_round
	};
};

const mapDispatchToProps = {};

export const PostVote = withRoundsSelector(
	connect(mapStateToProps, mapDispatchToProps)(PostVoteComponent)
);

export default PostVote;
