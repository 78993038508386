import { createStore, compose, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { createLogger } from "redux-logger";

function configureStoreProd({ rootReducer, api }) {
	const store = createStore(rootReducer, applyMiddleware(thunk.withExtraArgument({ api })));

	return store;
}

function configureStoreDev({ rootReducer, api }) {
	const middlewares = [
		// thunk middleware can also accept an extra argument to be passed to each thunk action
		// https://github.com/gaearon/redux-thunk#injecting-a-custom-argument
		thunk.withExtraArgument({ api }),
		createLogger({ collapsed: true })
	];

	// add support for Redux dev tools
	const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose; // eslint-disable-line no-underscore-dangle
	const store = createStore(rootReducer, composeEnhancers(applyMiddleware(...middlewares)));

	// if (module.hot) {
	// 	// Enable Webpack hot module replacement for reducers
	// 	module.hot.accept("../reducers", () => {
	// 		const nextReducer = require("../reducers").default; // eslint-disable-line global-require
	// 		store.replaceReducer(nextReducer);
	// 	});
	// }

	return store;
}

const configureStore = process.env.NODE_ENV === "prod" ? configureStoreProd : configureStoreDev;

export default configureStore;
