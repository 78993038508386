import { combineReducers } from "redux";
import _ from "lodash";
import {
	VOTE_FOR_PLAYER,
	SUBMIT_VOTE_BEGIN,
	SUBMIT_VOTE_SUCCESS,
	SUBMIT_VOTE_FAIL,
	CLEAR_VOTE_ERRORS
} from "../constants/actionTypes/voting";

const player = (state = null, action) => {
	switch (action.type) {
		case VOTE_FOR_PLAYER:
			return { ..._.get(action, "player", null) };
		default:
			return state;
	}
};
const isSubmitting = (state = false, action) => {
	switch (action.type) {
		case SUBMIT_VOTE_BEGIN:
			return true;
		case SUBMIT_VOTE_FAIL:
		case SUBMIT_VOTE_SUCCESS:
			return false;
		default:
			return state;
	}
};
const submitted = (state = false, action) => {
	switch (action.type) {
		case SUBMIT_VOTE_FAIL:
			return false;
		case SUBMIT_VOTE_SUCCESS:
			return true;
		default:
			return state;
	}
};
const error = (state = null, action) => {
	switch (action.type) {
		case SUBMIT_VOTE_FAIL:
			return action.error;
		case SUBMIT_VOTE_SUCCESS:
		case CLEAR_VOTE_ERRORS:
			return null;
		default:
			return state;
	}
};

const voting = combineReducers({
	player,
	isSubmitting,
	submitted,
	error
});

export default voting;
