import _ from "lodash";
import {
	VOTE_FOR_PLAYER,
	SUBMIT_VOTE_BEGIN,
	SUBMIT_VOTE_FAIL,
	SUBMIT_VOTE_SUCCESS,
	CLEAR_VOTE_ERRORS
} from "../constants/actionTypes/voting";

function voteForPlayer(player) {
	return { type: VOTE_FOR_PLAYER, player: player };
}

function submitVoteBegin() {
	return {
		type: SUBMIT_VOTE_BEGIN
	};
}

function submitVoteSuccess() {
	return {
		type: SUBMIT_VOTE_SUCCESS
	};
}

function submitVoteFail(error) {
	return {
		type: SUBMIT_VOTE_FAIL,
		error
	};
}

function clearErrors() {
	return {
		type: CLEAR_VOTE_ERRORS
	};
}

function submitVote(data) {
	return async (dispatch, getState, { api }) => {
		if (isSubmitting(getState())) {
			return false;
		}

		dispatch(submitVoteBegin());

		try {
			const answer_result = await api.questions.submitAnswer(data.answer);
			// console.log(answer_result);
			checkIfError(answer_result);
			const user_result = await api.questions.submitUserData(data.user_data);
			// console.log(user_result);
			checkIfError(user_result);
			return dispatch(submitVoteSuccess());
		} catch (error) {
			console.error(error);
			return dispatch(submitVoteFail(error));
		}
	};
}

const checkIfError = response => {
	const { data } = response;
	if (!data.success) {
		console.error(data.errors);
		throw _.get(data.errors, 0, { text: "Unknown error" });
	}
};

export default {
	voteForPlayer,
	submitVote,
	clearErrors
};

export const isSubmitting = state => state.voting.isSubmitting;
