import * as React from "react";
import styled from "styled-components";
import Title from "../../atoms/Title";
import HowDoTheyWork from "../../../assets/images/HowDoTheyWork.png";

const ArticleLink = styled.a`
	position: relative;
	display: block;

	/* animation-name: pulse;
	animation-duration: 1s;
	animation-iteration-count: infinite;
	animation-play-state: paused; */

	transition: transform 0.2s ease 0.1s;

	> img {
		width: 100%;
	}
	:hover {
		/* animation-play-state: running; */
		transform: scale(1.01);
	}
	@media screen and (max-width: 768px) {
		margin: 0 -20px;
	}
`;
const ArticleWrapper = styled.div`
	margin-bottom: 40px;

	@media screen and (max-width: 768px) {
		margin: 40px 20px 30px;
	}
`;

export const Article = () => {
	return (
		<ArticleWrapper>
			<Title>How do Nissan Net Points work? Click below to find out!</Title>
			<ArticleLink href="https://supernetball.com.au/net-points/" target="_blank">
				<img alt="How do Nissan Net Points work?" src={HowDoTheyWork} />
			</ArticleLink>
		</ArticleWrapper>
	);
};

export default Article;
