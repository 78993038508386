import _ from "lodash";
import moment from "moment";
import { EMAIL_REGEX } from "../../../../utils/email";

const email = value =>
	value && _.isString(value) && value.match(EMAIL_REGEX) ? undefined : "Should be an email";
const required = value => (value ? undefined : "Required");
const date = value => {
	if (value) {
		const in_past = moment(value).isBefore(new Date());
		return in_past ? undefined : "Invalid Date";
	}
	return undefined;
};

const votingForm = values => {
	const validateKey = (key, checks) => !_.some(_.map(checks, check => check(_.get(values, key))));
	return !_.some([
		validateKey("firstName", required),
		validateKey("lastName", required),
		validateKey("email", [required, email]),
		validateKey("dob", [required, date]),
		validateKey("termsAndConditions", required)
	]);
};

export const validate = {
	email,
	required,
	date,
	votingForm
};

export default validate;
