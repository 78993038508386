import React from "react";

export const Tick = ({ unit = "em", ...rest } = {}) => (
	<svg viewBox="0 0 24 24" {...rest}>
		<g id="UI/Icon/Tick/White" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<polygon
				id="Path"
				fill="#FFFFFF"
				points="9.50069894 17.93584 4.50209682 12.9372379 6.37657262 11.0627621 9.50069894 14.1868884 17.6234274 6.06415998 19.4979032 7.93863578"
			/>
		</g>
	</svg>
);

export default Tick;
