import styled from "styled-components";

export const CourtLayout = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;

	min-height: 350px;
	max-width: 1090px;
	margin: 0 auto;

	> div.mobile {
		display: none;
	}

	@media screen and (min-width: 769px) and (max-width: 1024px) {
		padding: 105px 0;
	}

	@media screen and (max-width: 768px) {
		padding: 30px 10px;

		> div.mobile {
			display: flex;
		}

		> div.desktop {
			display: none;
		}
	}
`;

export default CourtLayout;
