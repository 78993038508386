import _ from "lodash";
import { createSelector } from "reselect";

export const getQuestionTeam = createSelector(
	(state, props) => props.round,
	round => {
		const options = _.get(round, ["question", "options"], []);

		return _.keyBy(options, "playerPosition");
	}
);
