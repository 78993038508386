import * as React from "react";
import styled from "styled-components";
import colors from "../../../assets/colors";

const MainTitle = styled.div`
	font-size: 36px;
	color: ${colors.primary.primary};
	font-family: Radikal;
	text-align: center;

	max-width: 80%;

	margin-bottom: 40px;

	> span {
		font-weight: 600;
	}
	@media screen and (max-width: 768px) {
		font-size: 30px;
		max-width: calc(100% - 40px);
	}
`;

const SubTitle = styled.div`
	font-size: 26px;
	color: white;
	font-family: Radikal;
	text-align: center;
	max-width: 70%;

	@media screen and (max-width: 768px) {
		font-size: 20px;
		max-width: calc(100% - 60px);
	}
`;

const ComingSoonWrapper = styled.div`
	margin-bottom: 40px;

	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;

	padding: 80px 0;

	@media screen and (max-width: 768px) {
		margin: 40px 20px 30px;
	}
`;

export const ComingSoonOffSeason = () => {
	return (
		<ComingSoonWrapper>
			<MainTitle>
				The <span>Nissan Net Points Game Changer</span>
				<br />
				<span>Competition</span> is closed
			</MainTitle>
			<SubTitle>
				Make sure to come back as soon as we're live again!
			</SubTitle>
		</ComingSoonWrapper>
	);
};

export default ComingSoonOffSeason;
