import * as React from "react";
import _ from "lodash";
import styled from "styled-components";
import PropTypes from "prop-types";
import colors from "../../../../assets/colors";
import Caution from "../../../Icons/Caution";
import Search from "../../../Icons/Search";
import Label from "../Label";

export const StyledInput = styled.input`
	color: ${colors.text.disabled};
	font-size: 14px;
	font-family: Radikal;
	font-weight: 600;

	border: none;
	padding: 0 0 3px 0;
	margin: 0;

	width: 100%;
	height: 100%;

	background-color: transparent;

	transition: color 0.2s linear;

	&:disabled {
		opacity: 0.5;
	}

	::placeholder {
		transition: color 0.2s linear;
		color: ${colors.text.disabled};
	}

	:focus {
		outline: none;
		color: ${colors.text.black};
		::placeholder {
			color: ${colors.text.black};
		}
	}
`;

const InputWrapper = styled.div`
	box-sizing: content-box;
	position: relative;
	${({ textarea }) => !textarea && "height: 60px;"}

	border-radius: 5px;
	border: 1px solid ${({ is_error }) => (is_error ? colors.form.error : colors.form.border)};
	background-color: white;

	display: flex;
	align-items: center;
	justify-content: space-between;

	> input,
	> textarea {
		box-sizing: border-box;
		padding: 0 20px;
		${({ is_date }) => is_date && "padding-right: 40px;"}
		border-radius: 5px;
	}

	> textarea {
		padding: 20px;
		min-height: 90px;
		resize: vertical;
	}

	> svg {
		height: 24px;
		width: 24px;
		position: absolute;
		top: 50%;
		right: 12px;
		transform: translateY(-50%);
	}
`;

const InputFieldWrapper = styled.div`
	width: 100%;
`;

const renderIcon = (is_error, is_search) => {
	if (is_error) {
		return <Caution />;
	}
	if (is_search) {
		return <Search />;
	}
	return null;
};

export class Input extends React.Component {
	static defaultProps = {
		is_search: false
	};
	render() {
		const { label, input, meta, is_search, as, type, error, ...rest } = this.props;
		const name = input.name;
		const is_error = !_.isNil(error) || (meta.touched && meta.error !== undefined);

		return (
			<InputFieldWrapper>
				{label && (
					<Label htmlFor={name}>
						{label}
						<span className="error">{is_error && ` - ${error || meta.error}`}</span>
					</Label>
				)}
				<InputWrapper
					is_error={is_error}
					textarea={as === "textarea"}
					is_date={type === "date"}
				>
					<StyledInput {...rest} {...input} type={type} as={as} id={name} />
					{renderIcon(is_error, is_search)}
				</InputWrapper>
			</InputFieldWrapper>
		);
	}
}

Input.propTypes = {
	placeholder: PropTypes.string,
	input: PropTypes.object.isRequired,
	meta: PropTypes.object.isRequired,
	label: PropTypes.any,
	is_search: PropTypes.bool
};

export default Input;
