import _ from "lodash";
import { createSelector } from "reselect";
import { getAllRoundsAsOrderedArray } from "../reducers/rounds";
import { SEASON_START_ROUND_ID } from "../constants/vars";

/**
 * Given a round and matching question, checks whether or not the the round is
 * disabled (i.e. whether or not the round can be clicked on in the round selector)
 */
export const roundIsDisabled = (round = {}, question) => {
	if (round.id < SEASON_START_ROUND_ID) {
		return true;
	}
	const enabled = round.status !== "scheduled";

	return !enabled;
};

export const getLastCompleteRound = rounds => {
	return _.last(_.filter(rounds, round => round.status === "complete"));
};

export const getLastCompleteRoundId = rounds => {
	return _.get(getLastCompleteRound(rounds), "id");
};

/**
 * Gets the rounds with matching questions attached, and whether or
 * not they're disabled
 */
export const getRounds = createSelector(
	getAllRoundsAsOrderedArray,
	state => state.questions.data,
	(rounds, questions) => {
		return _.map(rounds, (round, i) => {
			const round_question = _.get(questions, i);

			const question = {
				..._.get(round_question, ["questions", 0], {}),
				forPublish: Boolean(_.get(round_question, "forPublish", false)),
				locked: Boolean(_.get(round_question, "locked", false))
			};

			return {
				...round,
				disabled: roundIsDisabled(round, round_question),
				question
			};
		});
	}
);

export const getCurrentQuestionId = createSelector(
	state => state.questions.data,
	(state, props) => props.selected_round_id,
	(questions, id) => {
		if (_.isEmpty(questions) || !id) {
			return null;
		}
		const qround = _.find(questions, { id });

		return _.get(qround, ["questions", 0, "id"], null);
	}
);
