import axios from "axios";
import camelcaseKeys from "camelcase-keys";

const fetchSquads = async function fetchSquads() {
	// TODO:: Maybe create an API Client to deal with building the urls and possible error logging and management. The idea would to have an API Client that would be passed a config object that includes base urls and also pass in an error logging function
	const baseUrl = process.env.REACT_APP_JSON_URL;
	const cacheBust = `_=${Date.now()}`;
	const squadsPayload = await axios.get(`${baseUrl}/squads.json?${cacheBust}`);
	return camelcaseKeys(squadsPayload, { deep: true });
};

export default {
	fetchSquads
};
