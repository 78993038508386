import * as React from "react";
import styled from "styled-components";
import colors from "../../../assets/colors";
import Netball from "../../Icons/Netball";
import Tickets from "../../Icons/Tickets";
import Flights from "../../Icons/Flights";
import Accommodation from "../../Icons/Accommodation";

const IconsWrapper = styled.div`
	display: flex;
	justify-content: flex-start;
	margin-bottom: 30px;
	margin-left: -4px;

	> svg {
		height: 80px;
	}

	@media screen and (max-width: 768px) {
		margin-bottom: 20px;
		> svg {
			height: 60px;
		}
	}
`;

const MainPrize = styled.div`
	font-family: Radikal;
	color: ${colors.primary.primary};
	font-weight: 400;
	font-size: 36px;

	margin-bottom: 50px;

	> span {
		font-weight: 700;
	}

	@media screen and (max-width: 768px) {
		font-size: 26px;
		margin-bottom: 30px;
	}
`;

const WeeklyPrize = styled.div`
	font-family: Radikal;
	color: ${colors.primary.primary};
	font-weight: 400;
	font-size: 20px;

	display: flex;
	align-items: center;

	margin-bottom: 50px;

	> div > span {
		font-weight: 700;
	}

	> svg {
		height: 80px;
		width: 80px;
		flex: 0 0 auto;
		margin-right: 20px;
	}

	@media screen and (max-width: 768px) {
		font-size: 16px;
		margin-bottom: 30px;
		> svg {
			height: 60px;
		}
	}
`;

const PrizeInfoWrapper = styled.div`
	@media screen and (max-width: 768px) {
		margin: 0 20px 20px;
	}
`;
export const PrizeSection = () => {
	return (
		<PrizeInfoWrapper>
			<IconsWrapper>
				<Tickets />
				<Flights />
				<Accommodation />
			</IconsWrapper>
			<MainPrize>
				<span>We hope the introduction of NNP enhances your Netball experience</span> just
				as Nissan Intelligent Mobility enhances your driving experience.
			</MainPrize>
			<WeeklyPrizeSection />
		</PrizeInfoWrapper>
	);
};

export const WeeklyPrizeSection = () => {
	return (
		<WeeklyPrize>
			<Netball />
			<div>
				<span>Weekly prizes including a</span> signed SSN ball!
			</div>
		</WeeklyPrize>
	);
};

export default PrizeSection;
