import * as React from "react";
import _ from "lodash";
import Types from "../../../constants/types";
import MissingPlayer from "../../../assets/images/_missing.png";

export class PlayerAvatar extends React.Component {
	state = {
		error: false
	};
	componentDidUpdate(prev_props) {
		// When changing rounds, if an avatar was missing, it stays marked as missing,
		// so reset it if the ID changes
		const getId = props => _.get(props, "player.id");
		if (getId(this.props) !== getId(prev_props)) {
			this.setState({
				error: false
			});
		}
	}
	render() {
		const { player: { firstName = "Player", lastName = "Name", id } = {} } = this.props;
		const player_img = `${process.env.REACT_APP_MEDIA_URL}/players/${id}.png`;
		if (this.state.error) {
			return <img src={MissingPlayer} alt={`${firstName} ${lastName}`} />;
		}
		return (
			<img
				src={player_img}
				alt={`${firstName} ${lastName}`}
				onError={() =>
					this.setState({
						error: true
					})
				}
			/>
		);
	}
}

PlayerAvatar.propTypes = {
	player: Types.player.isRequired
};

export default PlayerAvatar;
