import styled from "styled-components";
import colors from "../../../assets/colors";

export const StatsSection = styled.div`
	display: flex;
	height: 40px;
	width: 100%;
	box-sizing: border-box;
	background-color: white;
	color: ${colors.text.black};
	align-items: center;
	padding: 0 10px;
	@media screen and (max-width: 1024px) {
		height: 24px;
		padding: 0 5px;
	}
`;

export default StatsSection;
