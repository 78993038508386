import React from "react";

export const Caution = ({ unit = "em", ...rest } = {}) => (
	<svg viewBox="0 0 24 24" {...rest}>
		<g id="UI/Icon/Caution/Red" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<path
				d="M19.4166968,14.2014594 L21.7760622,18.2879995 C22.4123951,19.3902906 21.616836,20.7681544 20.3441701,20.7681544 L3.65582991,20.7681544 C2.38316399,20.7681544 1.58760485,19.3902906 2.22393781,18.2879995 L4.58330323,14.2014594 L4.57277479,14.2018614 L10.5627662,3.8267183 C10.8819432,3.27382238 11.441335,2.9982524 12,3 C12.558665,2.9982524 13.1180568,3.27382238 13.4372338,3.8267183 L19.4272252,14.2018614 L19.4166968,14.2014594 L19.4166968,14.2014594 Z M11.8055228,6.92348155 C11.1611857,6.92348155 10.6449156,7.45718938 10.6663553,8.10124067 L10.8658883,14.0895169 C10.8864705,14.7038384 11.3901626,15.1912363 12.0050558,15.1912363 C12.619949,15.1912363 13.1236411,14.7038384 13.1442233,14.0895169 L13.3437563,8.10124067 C13.3651961,7.45718938 12.848926,6.92348155 12.2045888,6.92348155 L11.8055228,6.92348155 Z M12.0050558,19.3248277 C12.7663113,19.3248277 13.3829197,18.7079335 13.3829197,17.9469639 C13.3829197,17.1859943 12.7660254,16.5691001 12.0050558,16.5691001 C11.2440862,16.5691001 10.627192,17.1859943 10.627192,17.9469639 C10.627192,18.7079335 11.2440862,19.3248277 12.0050558,19.3248277 Z"
				id="Shape"
				fill="#DF004D"
				fillRule="nonzero"
			/>
		</g>
	</svg>
);

export default Caution;
