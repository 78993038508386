import {
	FETCH_SQUADS_BEGIN,
	FETCH_SQUADS_SUCCESS,
	FETCH_SQUADS_FAIL
} from "../constants/actionTypes/squads";
import { getSquadsChecksum, isFetchingSquads } from "../reducers/squads";

function fetchSquadsBegin() {
	return {
		type: FETCH_SQUADS_BEGIN
	};
}

function fetchSquadsSuccess(squads, checksum) {
	return {
		type: FETCH_SQUADS_SUCCESS,
		squads,
		checksum
	};
}

function fetchSquadsFail(error) {
	return {
		type: FETCH_SQUADS_FAIL,
		error
	};
}

function fetchSquads() {
	return async (dispatch, getState, { api }) => {
		if (isFetchingSquads(getState())) {
			return false;
		}
		dispatch(fetchSquadsBegin());
		try {
			const checksums = await api.checksums.fetchChecksums();
			const squadsChecksum = checksums.data.squads;
			const isDataStale = squadsChecksum !== getSquadsChecksum(getState());
			if (!isDataStale) {
				return dispatch(fetchSquadsSuccess());
			}
			const squads = await api.squads.fetchSquads();
			return dispatch(fetchSquadsSuccess(squads.data, squadsChecksum));
		} catch (error) {
			console.error(error);
			// if (sentryClient) {
			// 	sentryClient.captureException(error, {
			// 		extra: {
			// 			failedAction: "fetchSquads"
			// 		}
			// 	});
			// }
			return dispatch(fetchSquadsFail(error));
		}
	};
}

export default {
	fetchSquads
};
