import styled from "styled-components";
import CardWrapper from "../../components/atoms/PlayerCard/CardWrapper";
export const CourtRow = styled.div.attrs(props => ({
	className: props.desktop ? "desktop" : "mobile"
}))`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;

	${CardWrapper} {
		margin-bottom: 20px;
		:last-of-type {
			margin-bottom: 0;
		}
	}
`;

export default CourtRow;
