import { combineReducers } from "redux";
import _ from "lodash";
import {
	FETCH_SQUADS_SUCCESS,
	FETCH_SQUADS_BEGIN,
	FETCH_SQUADS_FAIL
} from "../constants/actionTypes/squads";

const byId = (state = {}, action) => {
	switch (action.type) {
		case FETCH_SQUADS_SUCCESS:
			if (!action.squads) {
				return state;
			}
			return _.keyBy(action.squads, "id");
		default:
			return state;
	}
};

const allIdsOrdered = (state = [], action) => {
	switch (action.type) {
		case FETCH_SQUADS_SUCCESS:
			if (!action.squads) {
				return state;
			}
			return action.squads.map(squad => squad.id);
		default:
			return state;
	}
};

const isFetching = (state = false, action) => {
	switch (action.type) {
		case FETCH_SQUADS_BEGIN:
			return true;
		case FETCH_SQUADS_SUCCESS:
			return false;
		case FETCH_SQUADS_FAIL:
			return false;
		default:
			return state;
	}
};

const checksum = (state = null, action) => {
	switch (action.type) {
		case FETCH_SQUADS_SUCCESS:
			if (!action.checksum) {
				return state;
			}
			return action.checksum;
		default:
			return state;
	}
};

const squads = combineReducers({
	byId,
	allIdsOrdered,
	checksum,
	isFetching
});

export default squads;

// Squads state selectors
export const getAllSquadsAsOrderedArray = state =>
	state.squads.allIdsOrdered.map(id => state.squads.byId[id]);

export const getAllSquads = state => state.squads.byId;

export const isFetchingSquads = state => state.squads.isFetching;

export const isEmptySquads = state => state.squads.allIdsOrdered.length === 0;

export const getSquadsChecksum = state => state.squads.checksum;
