import {
	FETCH_PLAYERS_BEGIN,
	FETCH_PLAYERS_SUCCESS,
	FETCH_PLAYERS_FAIL
} from "../constants/actionTypes/players";
import { getPlayersChecksum, isFetchingPlayers } from "../reducers/players";

function fetchPlayersBegin() {
	return {
		type: FETCH_PLAYERS_BEGIN
	};
}

function fetchPlayersSuccess(players, checksum) {
	return {
		type: FETCH_PLAYERS_SUCCESS,
		players,
		checksum
	};
}

function fetchPlayersFail(error) {
	return {
		type: FETCH_PLAYERS_FAIL,
		error
	};
}

function fetchPlayers() {
	return async (dispatch, getState, { api }) => {
		if (isFetchingPlayers(getState())) {
			return false;
		}

		dispatch(fetchPlayersBegin());

		try {
			const checksums = await api.checksums.fetchChecksums();
			const playersChecksum = checksums.data.players;
			const isDataStale = playersChecksum !== getPlayersChecksum(getState());

			if (!isDataStale) {
				return dispatch(fetchPlayersSuccess());
			}

			const players = await api.players.fetchPlayers();

			return dispatch(fetchPlayersSuccess(players.data, playersChecksum));
		} catch (error) {
			console.error(error);
			// if (sentryClient) {
			// 	sentryClient.captureException(error, {
			// 		extra: {
			// 			failedAction: "fetchPlayers"
			// 		}
			// 	});
			// }
			return dispatch(fetchPlayersFail(error));
		}
	};
}

export default {
	fetchPlayers
};
