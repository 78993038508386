import {
	FETCH_POSITIONS_BEGIN,
	FETCH_POSITIONS_SUCCESS,
	FETCH_POSITIONS_FAIL
} from "../constants/actionTypes/positions";
import { getPositionsChecksum, isFetchingPositions } from "../reducers/positions";

function fetchPositionsBegin() {
	return {
		type: FETCH_POSITIONS_BEGIN
	};
}

function fetchPositionsSuccess(positions, checksum) {
	return {
		type: FETCH_POSITIONS_SUCCESS,
		positions,
		checksum
	};
}

function fetchPositionsFail(error) {
	return {
		type: FETCH_POSITIONS_FAIL,
		error
	};
}

function fetchPositions() {
	return async (dispatch, getState, { api }) => {
		if (isFetchingPositions(getState())) {
			return false;
		}

		dispatch(fetchPositionsBegin());

		try {
			const checksums = await api.checksums.fetchChecksums();
			const positionsChecksum = checksums.data.positions;
			const isDataStale = positionsChecksum !== getPositionsChecksum(getState());

			if (!isDataStale) {
				return dispatch(fetchPositionsSuccess());
			}

			const positions = await api.positions.fetchPositions();
			return dispatch(fetchPositionsSuccess(positions.data, positionsChecksum));
		} catch (error) {
			console.error(error);
			// if (sentryClient) {
			// 	sentryClient.captureException(error, {
			// 		extra: {
			// 			failedAction: "fetchPositions"
			// 		}
			// 	});
			// }
			return dispatch(fetchPositionsFail(error));
		}
	};
}

export default {
	fetchPositions
};
