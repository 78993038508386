import * as React from "react";
import styled from "styled-components";
import _ from "lodash";
import Types from "../../../constants/types";
import colors from "../../../assets/colors";
import Title from "../Title";

const SelectedPlayerWrapper = styled.div``;

const PlayerName = styled.div`
	font-family: Radikal;
	font-weight: 700;
	text-transform: uppercase;

	font-size: 40px;
	color: ${({ has_player }) => (has_player ? colors.primary.primary : colors.text.disabled)};

	@media screen and (max-width: 768px) {
		font-size: 30px;
	}
`;

const getPlayerName = player => {
	if (_.isNil(player)) {
		return "No one yet!";
	}
	const { firstName, lastName } = player;
	return `${firstName} ${lastName}`;
};

export const SelectedPlayer = ({ player }) => {
	const has_player = !_.isNil(player);

	return (
		<SelectedPlayerWrapper>
			<Title>You have selected</Title>
			<PlayerName has_player={has_player}>{getPlayerName(player)}</PlayerName>
		</SelectedPlayerWrapper>
	);
};

SelectedPlayer.propTypes = {
	player: Types.player
};

export default SelectedPlayer;
