import * as React from "react";
import _ from "lodash";
import { connect } from "react-redux";
import CourtLayout from "../../containers/CourtLayout";
import CourtRow from "../../containers/CourtLayout/row";
import PlayerCard from "../atoms/PlayerCard";
import { getQuestionTeam } from "../../selectors/team";

export class TeamOfTheWeekComponent extends React.Component {
	getPlayer(pos_id) {
		const { selected_player, positions, team, round, players } = this.props;

		const option = _.get(team, pos_id, {});
		const round_id = _.get(round, "id");

		const player = _.get(players, option.playerId, {});

		const net_points = _.get(player, ["stats", "scores", round_id], "--");

		return {
			position: _.get(positions, [pos_id, "shortName"]),
			net_points,
			player: {
				...player,
				option_id: option.id
			},
			selected: selected_player && selected_player.option_id === option.id,
			selection_active: !_.get(round, "question.locked", true) && selected_player !== null,
			clickable: !_.get(round, "question.locked", true),
			winner: Boolean(option.correct)
		};
	}
	renderDesktop() {
		return (
			<React.Fragment>
				<CourtRow desktop>
					<PlayerCard {...this.getPlayer(7)} />
				</CourtRow>
				<CourtRow desktop>
					<PlayerCard {...this.getPlayer(6)} />
					<PlayerCard {...this.getPlayer(5)} />
				</CourtRow>
				<CourtRow desktop>
					<PlayerCard {...this.getPlayer(4)} />
				</CourtRow>
				<CourtRow desktop>
					<PlayerCard {...this.getPlayer(3)} />
					<PlayerCard {...this.getPlayer(2)} />
				</CourtRow>
				<CourtRow desktop>
					<PlayerCard {...this.getPlayer(1)} />
				</CourtRow>
			</React.Fragment>
		);
	}
	renderMobile() {
		return (
			<React.Fragment>
				<CourtRow mobile>
					<PlayerCard {...this.getPlayer(6)} />
					<PlayerCard {...this.getPlayer(3)} />
				</CourtRow>
				<CourtRow mobile>
					<PlayerCard {...this.getPlayer(7)} />
					<PlayerCard {...this.getPlayer(4)} />
					<PlayerCard {...this.getPlayer(1)} />
				</CourtRow>
				<CourtRow mobile>
					<PlayerCard {...this.getPlayer(5)} />
					<PlayerCard {...this.getPlayer(2)} />
				</CourtRow>
			</React.Fragment>
		);
	}
	render() {
		if (_.isEmpty(this.props.team)) {
			return null;
		}
		return (
			<CourtLayout>
				{this.renderMobile()}
				{this.renderDesktop()}
			</CourtLayout>
		);
	}
}

const mapStateToProps = (state, props) => ({
	selected_player: state.voting.player,
	team: getQuestionTeam(state, props),
	positions: state.positions.byId,
	players: state.players.byId
});

const mapDispatchToProps = {};

export const TeamOfTheWeek = connect(
	mapStateToProps,
	mapDispatchToProps
)(TeamOfTheWeekComponent);

export default TeamOfTheWeek;
