// @flow
import * as React from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { getAllRoundsAsOrderedArray, getAllRounds } from "../../../reducers/rounds";
import { getLastCompleteRoundId } from "../../../selectors/rounds";

const DEFAULT_ROUND = 1;

export const withRoundsSelector = WrappedComponent => {
	class WithRoundsSelector extends React.Component {
		constructor(props) {
			super(props);

			_.bindAll(this, ["setSelectedRoundId"]);
		}

		state = {
			selected_round_id: null
		};

		componentDidUpdate(prev_props) {
			const { rounds_ordered } = this.props;
			if (!prev_props.rounds_ordered.length && rounds_ordered.length) {
				this.setState({
					selected_round_id: getLastCompleteRoundId(rounds_ordered)
				});
			}
		}

		setSelectedRoundId(id, callback) {
			this.setState(
				{
					selected_round_id: id
				},
				callback
			);
		}

		render() {
			const { rounds_ordered, rounds_by_id, selected_round_id, ...rest } = this.props;
			const { selected_round_id: selected_round_id_state } = this.state;

			return (
				<WrappedComponent
					{...rest}
					rounds_ordered={rounds_ordered}
					rounds_by_id={rounds_by_id}
					selected_round_id={
						_.isNil(selected_round_id_state)
							? selected_round_id
							: selected_round_id_state
					}
					setSelectedRoundId={this.setSelectedRoundId}
				/>
			);
		}
	}

	const mapStateToProps = state => {
		const rounds_ordered = getAllRoundsAsOrderedArray(state);
		return {
			rounds_ordered,
			rounds_by_id: getAllRounds(state),
			selected_round_id: getLastCompleteRoundId(rounds_ordered) || DEFAULT_ROUND
		};
	};

	return connect(mapStateToProps)(WithRoundsSelector);
};

export default withRoundsSelector;
